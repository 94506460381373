<template>
  <div>
    <v-footer color="#6ABAA4" class="mt-15" style="bottom: 0px !important">
      <v-container>
        <v-row
          :class="$vuetify.breakpoint.xs ? 'mt-1 mb-5' : 'mt-5 mb-5'"
          align="start"
        >
          <v-col class="white--text col-12 col-lg-4 py-0">
            <v-row justify="start" no-gutters align="center" class="py-0">
              <!-- <v-col class="px-0 py-0" cols="1">
                <v-row justify="center" no-gutters>
                  <img src="@/assets/phoneFooter.svg"
                /></v-row>
              </v-col>
              <v-col cols="10" class="ml-2">
                <span class="footer_text">
                  <a :href="`tel: +52 33 4770 7126`" style="text-decoration: none; color: white">
                    +52 33 4770 7126
                  </a>
                  </span>
                <br />
                <span class="footer_text">
                  <a :href="`tel: +52 33 4770 7127`" style="text-decoration: none; color: white">
                    +52 33 4770 7127
                  </a>
                </span>
              </v-col> -->
              <v-col
                v-if="
                  $vuetify.breakpoint.xs ||
                  $vuetify.breakpoint.sm ||
                  $vuetify.breakpoint.md
                "
                class="py-0"
              >
                <v-row
                  :justify="$vuetify.breakpoint.xs ? 'end' : 'start'"
                  no-gutters
                >
                  <div class="social">
                    <a
                      href="https://www.facebook.com/Guadalajara-Pass-122214770854466"
                      target="_blank"
                    >
                      <img src="@/assets/fb.svg" alt="facebook" />
                    </a>
                    <a
                      href="https://twitter.com/GuadalajaraPass"
                      target="_blank"
                    >
                      <img
                        src="@/assets/twitter.svg"
                        alt="twitter"
                        class="ml-3 mr-3"
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@guadalajarapass/"
                      target="_blank"
                      class="mr-3"
                    >
                      <img src="@/assets/tiktok.svg" alt="tiktok" />
                    </a>
                    <a
                      href="https://www.instagram.com/guadalajarapass/"
                      target="_blank"
                    >
                      <img src="@/assets/instagram.svg" alt="instagram" />
                    </a>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              justify="start"
              no-gutters
              :class="$vuetify.breakpoint.xs ? 'mt-5 mb-5' : 'mb-2'"
              align="center"
            >
              <v-col class="px-0 py-0 pa-0" cols="1">
                <v-row justify="center" no-gutters align="center">
                  <img src="@/assets/mailFooter.svg" />
                </v-row>
              </v-col>
              <v-col cols="10" class="ml-2">
                <span class="footer_text" v-if="mazatlan">
                  <a
                    :href="`mailto:citypass@hoozaround.com`"
                    style="text-decoration: none; color: white"
                  >
                    citypass@hoozaround.com
                  </a>
                </span>
                <span class="footer_text" v-if="!mazatlan">
                  <a
                    :href="`mailto:support@guadalajarapass.com`"
                    style="text-decoration: none; color: white"
                  >
                    support@guadalajarapass.com
                  </a>
                </span>
              </v-col>
            </v-row>
            <v-row justify="start" no-gutters align="center">
              <v-col class="px-0 py-0" cols="1">
                <v-row justify="center" no-gutters align="center"
                  ><img src="@/assets/FooterMarker.svg"
                /></v-row>
              </v-col>
              <v-col cols="9" class="ml-2"
                ><span
                  class="footer_text"
                  style="cursor: pointer"
                  v-if="mazatlan"
                >
                  <a
                    :href="`https://www.google.com/maps/search/?api=1&query=20.7534731,-103.3937158`"
                    target="_blank"
                    style="text-decoration: none; color: white"
                    >IoP Academia SA de CV Circuito Monarquia Espagñola 268,
                    Real del Bosque, Zapopan, 45130, Jalisco, Mexico
                  </a>
                </span>
                <span
                  class="footer_text"
                  style="cursor: pointer"
                  v-if="!mazatlan"
                >
                  <a
                    :href="`https://maps.google.com/?q=Camara de Comercio Av. Vallarta 4095 Fracc. Camino Real, Zapopan, Jalisco, México`"
                    target="_blank"
                    style="text-decoration: none; color: white"
                    >Camara de Comercio<br />
                    Av. Vallarta 4095 Fracc.<br />
                    Camino Real, Zapopan,<br />
                    Jalisco, México
                  </a>
                </span></v-col
              >
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col class="px-0 py-0 text-center" cols="1"
                ><img src="@/assets/phone_icon.svg"
              /></v-col>
              <v-col cols="9" class="footer_text ml-2">
                <a class="router-link white--text" href="tel:+523327085414">
                  +52 33 2708 5414
                </a>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col class="px-0 py-0 text-center" cols="1"
                ><img src="@/assets/whatsapp_icon.svg"
              /></v-col>
              <v-col cols="9" class="footer_text ml-2">
                <a
                  class="router-link white--text"
                  href="https://wa.me/+523327085414"
                  target="_blank"
                  >+52 33 2708 5414</a
                >
              </v-col>
            </v-row>
          </v-col>
          <!-- LINKS FOR OTHER SCREENS -->
          <v-col
            class="ml-0 footer_text pa-0 col-3"
            v-if="!$vuetify.breakpoint.xs"
          >
            <v-col
              v-if="mazatlan"
              class="px-0 py-0"
              cols="8"
              xl="5"
              lg="5"
              md="8"
            >
              <a
                href="https://business.hoozaround.com/login"
                target="_blank"
                style="text-decoration: none; color: white"
              >
                <span style="cursor: pointer" class="router-link white--text">
                  <b>{{ "mzt_business_login1" | localize }}</b>
                  {{ "mzt_business_login2" | localize }}
                </span>
              </a>
            </v-col>
            <br v-if="mazatlan" />
            <span
              style="cursor: pointer"
              @click="openModal('terms')"
              class="router-link white--text"
              >{{ "footer_terms_and_conditions" | localize }}</span
            ><br /><br />
            <span
              style="cursor: pointer"
              @click="openModal('policy')"
              class="router-link white--text"
              >{{ "footer_privacy_policy" | localize }}</span
            ><br /><br />
            <router-link to="/faq" class="router-link white--text">{{
              "faq_label_heading" | localize
            }}</router-link>
            <br /><br />
            <!-- <span
              style="cursor: pointer"
              @click="openModal('cookies')"
              class="router-link white--text"
              >{{ "cookies_label" | localize }}</span
            ><br /><br /> -->
            <span>
              <a href="https://www.hoozie.io/" target="_blank">
                <v-img width="120px" src="@/assets/poweredByHoozie_white.svg" />
              </a>
            </span>
          </v-col>
          <!-- LINKS FOR MOBILE SCREENS -->
          <v-row v-else no-gutters align="center">
            <v-col>
              <v-col
                v-if="mazatlan"
                class="px-0 py-0"
                cols="8"
                xl="5"
                lg="5"
                md="8"
              >
                <a
                  href="https://business.hoozaround.com/login"
                  target="_blank"
                  style="text-decoration: none; color: white"
                >
                  <span
                    style="cursor: pointer"
                    class="router-link white--text"
                    >{{ "mzt_footer_local_businesses" | localize }}</span
                  ></a
                ></v-col
              ><br />
              <span
                style="cursor: pointer"
                @click="openModal('terms')"
                class="router-link white--text"
                >{{ "footer_terms_and_conditions" | localize }}</span
              ><br /><br />
              <span
                style="cursor: pointer"
                @click="openModal('policy')"
                class="router-link white--text"
                >{{ "footer_privacy_policy" | localize }}</span
              ><br /><br />
              <router-link to="/faq" class="router-link white--text">{{
                "faq_label_heading" | localize
              }}</router-link>
              <br /><br />
              <span
                style="cursor: pointer"
                @click="openModal('cookies')"
                class="router-link white--text"
                >{{ "cookies_label" | localize }}</span
              ><br /><br />
            </v-col>
            <span class="mr-4"
              ><v-img width="120px" src="@/assets/poweredByHoozie_white.svg"
            /></span>
          </v-row>
          <v-col class="col-12 col-lg-5 pa-0 mt-4">
            <v-row
              justify="start"
              class="ml-1 mb-5"
              v-if="
                !$vuetify.breakpoint.xs &&
                !$vuetify.breakpoint.sm &&
                !$vuetify.breakpoint.md
              "
            >
              <div class="social">
                <a
                  href="https://www.facebook.com/Guadalajara-Pass-122214770854466 "
                  target="_blank"
                  ><img src="@/assets/fb.svg" alt="facebook"
                /></a>
                <a href="https://twitter.com/GuadalajaraPass" target="_blank"
                  ><img
                    src="@/assets/twitter.svg"
                    alt="twitter"
                    class="ml-3 mr-3"
                /></a>
                <a
                  href="https://www.tiktok.com/@guadalajarapass/"
                  target="_blank"
                  class="mr-3"
                >
                  <img src="@/assets/tiktok.svg" alt="tiktok" />
                </a>
                <a
                  href="https://www.instagram.com/guadalajarapass/"
                  target="_blank"
                  ><img src="@/assets/instagram.svg" alt="instagram"
                /></a>
              </div>
            </v-row>
            <div class="white--text text-lg-left text-center" v-if="!mazatlan">
              {{ "home_title_citypass_supported_by" | localize }}
            </div>
            <div>
              <img
                class="mt-3"
                src="@/assets/footerSponsorsLogo/jaliscoLogo.svg"
                alt="sponsor"
                v-if="!mazatlan"
                style="margin-right: 14px"
              />
              <img
                class="mt-3"
                src="@/assets/footerSponsorsLogo/camaraDeLogo.svg"
                alt="sponsor"
                v-if="!mazatlan"
                style="margin-right: 14px"
              />
              <img
                class="mt-3"
                width="93px"
                src="@/assets/footerSponsorsLogo/guadalajaraLogo.png"
                alt="sponsor"
                v-if="!mazatlan"
                style="margin-right: 14px"
              />
              <img
                class="mt-3"
                width="93px"
                src="@/assets/footerSponsorsLogo/tourismoLogo.svg"
                alt="sponsor"
                v-if="!mazatlan"
                style="margin-right: 14px"
              />
              <img
                width="80px"
                class="mb-1"
                src="@/assets/transporteLogoWhite.png"
                alt="sponsor"
                v-if="!mazatlan"
                style="margin-right: 14px"
              />
              <img
                class="mt-3"
                src="@/assets/footerSponsorsLogo/ofvcLogoWhite.png"
                alt="sponsor"
                v-if="!mazatlan"
                width="35px"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <buy-citypass-btn v-if="$vuetify.breakpoint.xs && $route.path == '/'" />
    </v-footer>
    <terms-modal v-if="termsModal" @close_modal="closeModal" :rules="rules" />
  </div>
</template>

<script>
import BuyCitypassBtn from "./UI/buyCitypassBtn.vue";
import termsModal from "./UI/TermsModal.vue";
import mobile from "@/mixins/mobileMixin";
import cityMixin from "@/mixins/cityMixin";
export default {
  components: {
    termsModal,
    BuyCitypassBtn,
  },
  mixins: [mobile, cityMixin],
  data: () => ({
    termsModal: false,
    rules: "",
  }),
  methods: {
    closeModal() {
      this.rules = "";
      this.termsModal = false;
    },
    openModal(rules) {
      this.rules = rules;
      this.termsModal = true;
    },
  },
};
</script>

<style>
.router-link {
  text-decoration: none;
  color: white;
}
.footer_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 157.9%;
}
.social a {
  text-decoration: none;
}
</style>