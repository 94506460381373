import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '@/store/modules/mobile/types/actions';
import * as getterTypes from '@/store/modules/mobile/types/getters';

export default {
    methods: {
        ...mapActions('mobile', {
            setMobile: actionTypes.FETCH_MOBILE,
            setMobileMenu: actionTypes.FETCH_MENU,
        }),
    },
    computed: {
        ...mapGetters('mobile', {
            isMobile: getterTypes.GET_MOBILE,
            isMobileMenu: getterTypes.GET_MENU,
        }),
    },
};