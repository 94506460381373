import store from '@/store';
import $ from 'jquery'
import requestService from '../requestService';
const api = process.env.VUE_APP_API

export default {
	async getCart() {
		let response = $.ajax({
			url: `${api}carts`,
			type: "GET",
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` },
			xhrFields: { withCredentials: true },
		});
		return response
	},
	async addToCart(form) {
		let response = $.ajax({
			url: `${api}carts`,
			type: "POST",
			xhrFields: { withCredentials: true },
			dataType: 'json',
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` },
			data: JSON.stringify(form)
		});
		return response
	},
	async updateCart(uuid, form) {
		let response = $.ajax({
			url: `${api}carts/${uuid}`,
			type: "PUT",
			xhrFields: { withCredentials: true },
			dataType: 'json',
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` },
			data: JSON.stringify(form)
		});
		return response
	},
	async deleteFromCart(uuid) {
		let response = $.ajax({
			url: `${api}carts/delete/${uuid}`,
			type: "POST",
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` },
			xhrFields: { withCredentials: true },
		});
		return response
	},
	async clearCart() {
		let response = $.ajax({
			url: `${api}carts/clear`,
			type: "POST",
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` },
			xhrFields: { withCredentials: true },
		});
		return response
	},
	async createOrder(form) {
		let response = $.ajax({
			url: `${api}carts/create-order`,
			type: "POST",
			xhrFields: { withCredentials: true },
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			},
			data: JSON.stringify(form)
		});
		return response
	},
	async getListOrderForUser(isExpired) {
		let response = $.ajax({
			url: `${api}orders/auth?isExpired=${isExpired}`,
			type: "GET",
			xhrFields: { withCredentials: true },
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			},
		});
		return response
	},
	async getDetailOrder(order_uuid, parent_uuid) {
		let response = $.ajax({
			url: `${api}orders/${order_uuid}?parent_uuid=${parent_uuid}`,
			type: "GET",
			xhrFields: { withCredentials: true },
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			},
		});
		return response
	},
	async cartPreview(payload) {
		const response = await requestService.post(`carts/preview`, JSON.stringify(payload))
		return response?.data
	}
}