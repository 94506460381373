import store from '@/store';
import axios from 'axios';

const api = process.env.VUE_APP_API_CAMPUS

export default {
	async login(form) {
		const response = await axios.post(`${api}/web/signin`, form, {
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` }
		})
		return response?.data
	},
	async registration(form) {
		const response = await axios.post(`${api}/web/signup`, form, {
			headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` }
		})
		return response?.data
	},
	async checkEmail(form) {
		const response = await axios.post(`${api}/web/check_email`, form, {
			headers: { 'x-citypass-keyY': `${process.env.VUE_APP_X_API_KEY}` }
		})
		return response?.data
	},
	async checkCode(form) {
		const response = await axios.post(`${api}/web/activate_account`, form, {
			headers: {
				'X-API-KEY': `${store.getters.user.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async acceptAgreement() {
		const response = await axios.post(`${api}/web/accept_agreement`, {}, {
			headers: {
				'X-API-KEY': `${store.getters.user.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async resendCode() {
		const response = await axios.post(`${api}/web/resend_activation_code?lang=${store.getters.user.locale}`, {}, {
			headers: {
				'X-API-KEY': `${store.getters.user.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async resetPasswordUserEmail(form) {
		const response = await axios.post(`${api}/users/request_reset_password?client=citypass`, form, {
			headers: {
				'X-API-KEY': `${store.getters.user.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async checkResetPasswordCode(form) {
		const response = await axios.post(`${api}/users/check_recovery_code?client=citypass`, form, {
			headers: {
				'X-API-KEY': `${store.getters.user.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async changePassword(form) {
		const response = await axios.post(`${api}/users/reset_password?client=citypass`, form, {
			headers: {
				'X-API-KEY': `${store.getters.user.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	}

};