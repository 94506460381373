import requestService from '../requestService';
import store from '@/store';

export default {
	async addAttraction(form) {
		const response = await requestService.post(`citypass/attraction`, form, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async updateAttraction(uid, form) {
		const response = await requestService.post(`citypass/attraction/` + uid, form, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async updateAttractionOrder(form) {
		const response = await requestService.post(`citypass/attractions/order`, form, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async getAttractions() {
		const response = await requestService.get(`citypass/attractions/${store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale}`,
			{
				headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` }
			})
		return response?.data
	},
	async getAttractionsByUids(uids) {
		const response = await requestService.get(`citypass/attraction_list/${uids}/${store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale}`,
			{
				headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` }
			})
		return response?.data
	},
	async getBusinesses() {
		const response = await requestService.get(`citypass/businesses`, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async getAttraction(uid) {
		const response = await requestService.get(`citypass/attraction/` + (typeof uid === 'string' ? uid : uid.uuid) + `/${store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale}`,
			{
				headers: { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` }
			})
		return response?.data
	},
	async getAttractionForAdmin(uid) {
		const response = await requestService.get(`citypass/attraction/` + uid, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async deleteAttractionImage(auid, iuid) {
		const response = await requestService.post(`citypass/attraction_image/${auid}/${iuid}`, {}, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
}