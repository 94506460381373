<template>
  <v-dialog v-model="visibility" max-width="410px" persistent>
    <v-card>
      <v-card-title style="word-break: break-word !important">
        {{ text | localize }}
      </v-card-title>
      <v-card-actions class="pb-3">
        <v-row>
          <v-col>
            <v-btn block text color="#E62076" @click="onChoose(false)">
              {{'button_no' | localize}}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="#E62076" dark block @click="onChoose(true)">
              {{'button_yes' | localize}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    text: "attractions_transport_reminder",
  }),
  props: {
    visible: {
      require: true,
    },
    typeConfirm: {
      require: true,
      default: "noBus",
    },
  },
  mounted() {
    if (this.typeConfirm == "cart") {
      this.text = "attractions_same_warning";
    } else if (this.typeConfirm == "deleteCitypassFromCart") {
      this.text = "shopping_cart_delete_city_pass";
    } else if (this.typeConfirm == "deleteSlot") {
      this.text = "admin_prebooking_delete_slot_confirmation";
    } else {
      this.text = "attractions_include_transport_label";
    }
  },
  methods: {
    onChoose(check) {
      this.$emit("choosed", check);
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close_modal");
      },
    },
  },
};
</script>