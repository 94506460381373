<template>
  <v-container
    align="center"
    justify="center"
    fill-height
    fluid
    style="max-width: 550px"
    class="pa-0"
  >
    <v-progress-linear
      color="#6ABAA4"
      class="mb-2"
      v-if="loading"
      indeterminate
      rounded
      height="3"
    ></v-progress-linear>
    <div :class="loading ? 'disabled-form pa-2' : 'pa-2'">
      <v-col>
        <p
          class="text-center mb-3"
          cols="12"
          style="font-weight: 600; font-size: 32px; line-height: 39px"
        >
          {{ "sign_in_heading_sign_in" | localize }}
        </p>
      </v-col>
      <v-form @submit.prevent="onLogin">
        <v-row no-gutters>
          <v-col cols="12">
            <span class="label-login">{{
              "faq_placeholder_email" | localize
            }}</span>
            <v-text-field
              outlined
              dense
              type="email"
              class="mt-1"
              :disabled="loading"
              persistent-placeholder
              :error-messages="emailError | localize"
              background-color="white"
              v-model.trim="newUser.email"
            />
          </v-col>
          <v-col cols="12">
            <span class="label-login">{{
              "sign_in_placeholder_password" | localize
            }}</span>
            <v-text-field
              outlined
              class="iconPass mt-1"
              :disabled="loading"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="passwordVisible = !passwordVisible"
              :type="passwordVisible ? 'text' : 'password'"
              persistent-placeholder
              :hide-details="!passwordError.length"
              :error-messages="passwordError | localize"
              @input="message = ''"
              dense
              background-color="white"
              v-model.trim="newUser.password"
            />
          </v-col>
          <v-col class="col-12">
            <div>
              <span style="color: #c92424">{{ message }}</span>
            </div>
          </v-col>
          <v-col class="col-12">
            <div
              :disabled="loading"
              @click="
                $router.push('/reset_password');
                $emit('close_modal');
              "
              class="label-login mt-1"
              style="
                text-decoration: underline;
                cursor: pointer;
                width: max-content;
              "
            >
              {{ "sign_in_button_reset_password" | localize }}
            </div>
          </v-col>
          <!-- <v-col class="col-12 my-7">
            <v-row no-gutters justify="start" align="center">
              <img
                class="mr-1"
                src="@/assets/notChecked.svg"
                style="cursor: pointer"
                v-if="isAgree == 0"
                @click="isAgree = 1"
              />
              <img
                src="@/assets/chekedTerm.svg"
                v-if="isAgree == 1"
                class="mr-1"
                @click="isAgree = 0"
                style="cursor: pointer"
              />

              <span class="label-login"
                >{{ "cart_details_label_agree_policy1" | localize }}
                <span
                  @click="openModal()"
                  style="
                    color: black;
                    font-weight: 700;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  {{ "license_short_label" | localize }}</span
                >
              </span>
            </v-row>
          </v-col> -->
          <v-col class="col-12 mt-5">
            <v-row class="text-center w-100">
              <v-col class="col-6">
                <v-btn
                  color="#40B4E5"
                  text
                  style="
                    text-transform: none !important;
                    border: 1px solid #40b4e5;
                    border-bottom: 2px solid;
                    width: 100%;
                  "
                  large
                  :disabled="loading"
                  @click="$emit('to_reg')"
                  >{{ "button_signUp" | localize }}</v-btn
                >
              </v-col>
              <v-col class="col-6">
                <v-btn
                  :style="
                    isAgree == 0
                      ? 'text-transform: none !important; width: 100%; c'
                      : 'background: #6ABAA4;text-transform: none !important; border-bottom: 1px solid #4E8777;width: 100%'
                  "
                  class="br-10 white--text"
                  height="44px"
                  large
                  type="submit"
                  :disabled="loading || !isAgree"
                >
                  {{ "sign_in_heading_sign_in" | localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <terms-modal
      v-if="termsModal"
      :rules="'license'"
      @close_modal="closeModal"
    />
  </v-container>
</template>

<script>
import AuthService from "@/requests/Auth/AuthService";
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import CitypassService from "@/requests/Citypass/CitypassService";
import termsModal from "@/components/UI/TermsModal.vue";

export default {
  components: {
    termsModal,
  },
  mixins: [validationMixin],
  data: () => ({
    passwordVisible: false,
    loading: false,
    newUser: {},
    isShowResetDialog: false,
    message: "",
    termsModal: false,
    isAgree: true,
  }),
  validations: {
    newUser: {
      password: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },

  methods: {
    ...mapActions(["updateInfoLogged"]),
    ...mapActions(["updateInfo"]),
    closeModal() {
      this.termsModal = false;
    },
    openModal() {
      this.termsModal = true;
    },
    async onLogin() {
      this.$v.newUser.$touch();
      if (!this.$v.newUser.$invalid) {
        this.loading = true;
        let form = new FormData();
        form.append("email", this.newUser.email);
        form.append("password", this.newUser.password);
        form.append("lang", this.user.locale);
        await AuthService.login(form)
          .then(async (response) => {
            if (response.data.status) {
              this.updateInfoLogged({
                token: response.data.token,
                locale: this.user.locale || "es",
                id: response.data.id,
                user_type: response.data.user_type,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                profile_image: response.data.profile_image,
              });
              //if (window.location.href.indexOf("dev") == -1) {
              await CitypassService.checkAdmin()
                .then(() => {
                  this.updateInfoLogged({
                    admin: true,
                  });
                  console.log(this.loggedUser);
                })
                .catch(() => {
                  console.log(this.loggedUser);
                  this.updateInfoLogged({
                    admin: false,
                  });
                  console.log(this.loggedUser);
                });
              // } else {
              //   this.updateInfoLogged({
              //     admin: true,
              //   });
              // }
              // if (window.location.href.indexOf("localhost") > -1) {
              //   this.updateInfoLogged({
              //     admin: true,
              //   });
              // }
              //this.$router.push("/");
              this.loading = false;
              this.$emit("close_modal");
              if (
                this.$route.path !== "/checkout" &&
                this.$route.path !== "/mobile/checkout"
              ) {
                this.$router.push("/profile");
              }
            } else {
              this.$emit("to_code");
            }
          })
          .catch((e) => {
            this.loading = false;
            this.message = e.response.data.message;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    emailError() {
      const errors = [];
      if (!this.$v.newUser.email.$dirty) {
        return errors;
      }
      !this.$v.newUser.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.newUser.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.newUser.password.$dirty) {
        return errors;
      }
      !this.$v.newUser.password.required &&
        errors.push("sign_up_validation_password_required");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-login {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.disabled-form {
  opacity: 0.3;
}
</style>
