<template>
  <div style="position: relative; width: 100vw">
    <v-btn
      @click="toBuy"
      class="btn"
      :style="isMobile && isMobileMenu ? 'bottom: 95px;' : ''"
      color="#e62076"
      dark
    >
      {{ "home_buy_city_pass_button" | localize }}
    </v-btn>
    <!-- <announcement-modal
      :visible="announcementModal"
      v-if="announcementModal"
      @close="announcementModal = false"
    /> -->
  </div>
</template>

<script>
import mobile from "@/mixins/mobileMixin";
//import announcementModal from "@/components/UI/announcementModal.vue";
import serverMixin from "@/mixins/serverMixin";

export default {
  components: {
    //announcementModal,
  },
  data: () => ({
    //announcementModal: false,
  }),
  mixins: [mobile, serverMixin],
  methods: {
    toBuy() {
      // if (this.isProd) {
      //   this.announcementModal = true;
      // } else {
      if (this.isMobile) {
        this.$router.push({ name: "mobile_citypass" });
      } else {
        this.$router.push("/city_passes");
      }
      //}
    },
  },
};
</script>

<style>
.btn {
  border-bottom: 2px solid #9a104c;
  border-radius: 6px;
  width: 342px;
  height: 44px;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  z-index: 110;
  margin-top: 30px;
  transform: translate(calc(46vw - 50%));
}
</style>