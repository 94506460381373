<template>
  <v-dialog
    v-model="isVisible"
    scrollable
    :width="$vuetify.breakpoint.xs ? '375px' : '642px'"
    style="z-index: 1000; overflow-x: hidden"
  >
    <v-card
      :width="$vuetify.breakpoint.xs ? '375px' : '642px'"
      :style="$vuetify.breakpoint.xs ? 'overflow: hidden' : ''"
      style="overflow-x: hidden"
      height="100%"
    >
      <div>
        <v-row justify="start" no-gutters align="center" class="mb-5">
          <v-row justify="start" class="mt-5">
            <h2 class="ml-8" v-if="cartOrder">
              {{ "cart_label_shopping_cart" | localize }} ({{
                cartOrder.length
              }})
            </h2>
          </v-row>
          <v-row justify="end" class="mt-0">
            <v-icon
              class="mr-5"
              color="#212121"
              large
              style="cursor: pointer"
              @click="closeCart"
            >
              mdi-close
            </v-icon>
          </v-row>
        </v-row>
        <v-row justify="center">
          <div
            v-if="!cartOrder && !showLoader"
            class="d-flex align-center"
            style="min-height: 50vh"
          >
            <span style="font-size: 18px; color: black">
              {{ "cart_label_empty_cart" | localize }}
            </span>
          </div>
          <loader v-if="showLoader" />
        </v-row>
        <v-row
          v-if="!showLoader"
          :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
          no-gutters
        >
          <div
            style="width: 400px"
            :class="$vuetify.breakpoint.xs ? 'ml-0' : 'ml-2'"
          >
            <cart-order-list
              :cartOrder="cartOrder"
              @newCart="refactorCart"
              @deleteCitypassFromCart="showConfirmModal"
              @changeSubtotal="changeSubtotal"
              :key="key"
              v-if="!$vuetify.breakpoint.xs"
            />
            <v-row v-else justify="center" class="mt-2">
              <cart-order-list-mobile
                :cartOrder="cartOrder"
                @newCart="refactorCart"
                @deleteCitypassFromCart="showConfirmModal"
                @changeSubtotal="changeSubtotal"
                :key="key"
              />
            </v-row>
          </div>
        </v-row>
        <!-- Mobile total cost -->
        <v-col
          v-if="cartOrder && $vuetify.breakpoint.xs"
          class="mt-2 bigMobileScreen"
        >
          <v-row justify="end">
            <span class="subtotalTextStyle">
              {{ "cart_label_total_cost" | localize }}&nbsp;
            </span>
            <span class="subtotalTextStyle" style="font-weight: 700">
              MX$&nbsp;
              {{
                new Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 2,
                }).format(orderTotal)
              }}
            </span>
          </v-row>
        </v-col>
        <!-- /Mobile total cost -->
        <!-- For other screen total cost -->
        <v-col class="px-7" v-if="cartOrder && !$vuetify.breakpoint.xs">
          <v-row justify="end">
            <span class="subtotalTextStyle">
              {{ "cart_label_total_cost" | localize }}:&nbsp;
              <span style="font-weight: 700; margin-right: 5px">
                MX$&nbsp;{{
                  new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                  }).format(orderTotal)
                }}
              </span>
            </span>
          </v-row>
        </v-col>
        <!-- /For other screen total cost -->
        <v-card-actions>
          <v-row justify="center" no-gutters class="mb-5 mt-3 px-3">
            <v-btn
              :width="$vuetify.breakpoint.xs ? '290px' : '255px'"
              height="44px"
              style="
                text-transform: unset !important;
                font-weight: 700;
                font-size: 16px;
                background: #6abaa4;
                border-bottom: 2px solid #4e8777;
                border-radius: 6px;
              "
              @click="toAttractions"
              class="white--text"
              :class="$vuetify.breakpoint.xs ? 'mb-5' : 'mr-5'"
            >
              {{ "cart_button_add_another_pass" | localize }}
            </v-btn>
            <br v-if="$vuetify.breakpoint.xs" />
            <v-spacer v-else />
            <v-btn
              :width="$vuetify.breakpoint.xs ? '290px' : '255px'"
              height="44px"
              style="
                text-transform: unset !important;
                font-weight: 700;
                font-size: 16px;
                background: #e62076;
                border-bottom: 2px solid #9a104c;
                border-radius: 6px;
              "
              @click="toCheckout"
              :disabled="
                !cartOrder.length ||
                !cartOrder.some((item) =>
                  item.number.some((obj) => parseInt(obj.number) !== 0)
                )
              "
              class="white--text"
            >
              {{ "cart_button_proceed_to_checkout" | localize }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </div>
    </v-card>
    <confirm-modal
      v-if="isShowConfirmModal"
      :visible="isShowConfirmModal"
      typeConfirm="deleteCitypassFromCart"
      @choosed="onChoose"
    />
  </v-dialog>
</template>

<script>
import cartOrderList from "./cartOrderList.vue";
import CartService from "../../requests/Cart/CartService";
import CitypassService from "../../requests/Citypass/CitypassService";
import cartOrderListMobile from "./cartOrderListMobile.vue";
import Loader from "../UI/Loader.vue";
import mobile from "@/mixins/mobileMixin";
import confirmModal from "@/components/OurAttractions/confirmModal";

export default {
  components: {
    cartOrderList,
    cartOrderListMobile,
    Loader,
    confirmModal,
  },
  mixins: [mobile],
  data: () => ({
    isVisible: true,
    cartOrder: [],
    showLoader: true,
    isShowConfirmModal: false,
    bookingFee: 0,
    key: 0,
    timeout: null,
    orderTotal: 0,
    citypassForDeleteUid: "",
  }),
  mounted() {
    this.getCart();
  },
  methods: {
    refactorCart(newCart) {
      this.cartOrder = newCart;
    },
    async getCart(cartRes = {}, updated = false) {
      //Get cart
      let cart = {}
      if (updated) {
       cart = { ...cartRes };
      } else {
       cart = await CartService.getCart();
      }
      console.log(cart);
      //Initinal response
      let response = cart.result.list.reverse();
      //Total price for order
      this.orderTotal = cart.result.total;
      //Added count product in cart
      localStorage.count = response.length;
      //Emit success get order for cart
      this.$emit("get_cart", response.length);
      //Temporary array for new data
      let temporaryArray = [];
      //Cycle to get information about citypass
      for (let i = 0; i < response.length; i++) {
        // get max available count according events
        let adult_availables = [];
        let child_availables = [];
        let maxAdultAvailable = 15;
        let maxChildAvailable = 40;
        response[i].attractions.forEach((attr) => {
          if (attr.event_uuid) {
            adult_availables.push(
              Number(attr.event_adult) -
                Number(attr.event_booked_adult) +
                response[i].quantity_adult
            );
            child_availables.push(
              Number(attr.event_kid) -
                Number(attr.event_booked_kid) +
                response[i].quantity_kid
            );
          }
        });
        maxAdultAvailable = adult_availables.length
          ? Math.min(...adult_availables)
          : 15;
        maxChildAvailable = child_availables.length
          ? Math.min(...child_availables)
          : 40;

        response[i].started_at = response[i].started_at.replace(/ /g, "T");
        let citypass = await this.getCitypass(response[i].citypass_uuid);
        temporaryArray.push({
          uuid: response[i].uuid,
          citypass_uuid: response[i].citypass_uuid,
          cityPassTitleEn: citypass.title_en,
          cityPassTitleEs: citypass.title_es,
          started_at: response[i].started_at,
          end_date: this.addDays(
            response[i].started_at,
            citypass.term,
            citypass.days
          ),
          attractions: response[i].attractions,
          color: citypass.color,
          fee: response[i].fee, //Or banking charges price
          additional: response[i].additional, //Or public transport price
          //Array for qunatity people
          number: [
            {
              id: 1,
              number: response[i].quantity_adult,
              type: "Adult",
              currentPrice: citypass.price_adult,
              maxAdultAvailable: maxAdultAvailable,
            },
            {
              id: 2,
              number: response[i].quantity_kid,
              type: "Kids",
              currentPrice: citypass.price_kid,
              maxChildAvailable: maxChildAvailable,
            },
          ],
          subtotal: response[i].total,
        });
      }
      //Assign new data for output
      this.cartOrder = temporaryArray;
      //Key for refresh page
      this.key++;
      //Can't show loader
      this.showLoader = false;
      //Check the number of citypasses in the cart, if the number is zero, the cart is automatically closed
      if (cart.result.total == 0) {
        setTimeout(this.closeCart, 4000);
      }
      //Check the number of citypasses in the order and route name, if the number is zero and route name is equal to '/checkout', route go to '/attractions' page
      if (
        cart.result.total == 0 &&
        (this.$route.name == "Checkout" ||
          this.$route.name == "mobile_checkout")
      ) {
        setTimeout(this.closeCart("/"), 5000);
      }
    },
    showConfirmModal(uid) {
      this.isShowConfirmModal = true;
      this.citypassForDeleteUid = uid;
    },
    onChoose(choose) {
      this.isShowConfirmModal = false;
      if (choose) {
        this.deleteCitypassFromCart(this.citypassForDeleteUid);
      }
    },
    async deleteCitypassFromCart(uuid) {
      await CartService.deleteFromCart(uuid);
      this.getCart();
    },
    async getCitypass(uuid) {
      let citypass = [];
      await CitypassService.getCitypass(uuid).then((response) => {
        citypass = response.result;
      });
      return citypass;
    },
    changeSubtotal(cart) {
      this.cartOrder = cart;
      //Sum subtotal after change quantity people
      // const initialValue = 0;
      // this.cartOrder.forEach(
      //   (order) =>
      //     (order.subtotal =
      //       order.number[0].number * parseInt(order.number[0].currentPrice) +
      //       order.number[1].number * parseInt(order.number[1].currentPrice) +
      //       order.additional +
      //       order.fee)
      // );
      // //Sum amount:
      // this.orderTotal = this.cartOrder.reduce(
      //   (previousValue, currentValue) => previousValue + currentValue.subtotal,
      //   initialValue
      // );
      //Lazzy update quantity people on server
      //this.updateCart();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.updateCart, 1200);
    },
    async updateCart() {
      for (let index = 0; index < this.cartOrder.length; index++) {
        let data = {
          uuid: this.cartOrder[index].uuid,
          citypass_uuid: this.cartOrder[index].citypass_uuid,
          quantity_adult: this.cartOrder[index].number[0].number,
          quantity_kid: this.cartOrder[index].number[1].number,
          started_at: this.cartOrder[index].started_at,
          attractions: this.cartOrder[index].attraction,
        };
        await CartService.updateCart(this.cartOrder[index].uuid, data).then(
          (res) => {
            console.log(res);
            this.snackbarSuccess = true;
            this.getCart(res, true);
          }
        );
      }
    },
    addDays(theDate, days, week) {
      console.log(theDate);
      let endDate = new Date(theDate);
      console.log(endDate);
      if (week && week != "1,2,3,4,5,6,7") {
        let day = week.substr(-1) - endDate.getDay();
        endDate.setDate(endDate.getDate() + parseInt(day));
      } else {
        endDate.setDate(endDate.getDate() + parseInt(days) - 1);
      }

      return endDate;
    },
    toCheckout() {
      if (
        this.$route.name == "mobile_checkout" ||
        this.$route.name == "Checkout"
      ) {
        this.$router.go();
      } else {
        if (this.isMobile) {
          this.$router.push({ name: "mobile_checkout" });
        } else {
          this.$router.push("/checkout");
        }
      }
      this.$emit("close");
      this.isVisible = false;
    },
    toAttractions() {
      if (this.isMobile) {
        this.$router.push({ name: "mobile_citypass" });
      } else {
        this.$router.push("/attractions");
      }
      this.$emit("close");
      this.isVisible = false;
    },
    closeCart(url) {
      if (url && this.$route.path !== url) {
        this.$router.push(url).catch(() => {});
      } else if (this.$route.path == url) {
        this.$router.go().catch(() => {});
      }
      this.$emit("close");
      this.isVisible = false;
    },
  },
};
</script>

<style>
.subtotalTextStyle {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media only screen and (min-width: 325px) and (max-width: 375px) {
  .bigMobileScreen {
    padding-right: 29px !important;
  }
}
@media only screen and (min-width: 380px) and (max-width: 600px) {
  .bigMobileScreen {
    padding-right: 55px !important;
  }
}
</style>
