import axios from 'axios';

const SERVER_API = process.env.VUE_APP_API

export default {
  get(url, headers = {}) {
    return axios.get(SERVER_API + url, headers);
  },
  getWithParams(url, params = {}, headers = {}) {
    return axios.get(SERVER_API + url, {params, headers});
  },
  post(url, body = {}, headers = {}, config = {}) {
    return axios.post(
      SERVER_API + url,
      body,
      headers,
      config,
    );
  },
  delete(url, headers = {}, config = {}) {
    return axios.delete(SERVER_API + url, headers, config);
  },
  put(url, body = {}, headers = {}, config = {}) {
    return axios.put(
      SERVER_API + url,
      body,
      headers,
      config,
    );
  },
  postExternal(url, body = {}, config = {}) {
    return axios.post(
      SERVER_API + url,
      body,
      config,
    );
  },
};