<template>
  <div>
    <v-col>
      <div class="cart_mobile mb-5" v-for="order in cart" :key="order.id">
        <div
          class="header_mobile px-0"
          :style="'background-color:' + order.color"
        >
          <v-row
            no-gutters
            style="width: 100% !important"
            align="center"
            class="pt-1"
          >
            <v-col cols="10" class="px-5">
              <v-row no-gutters justify="center">
                <span
                  style="font-weight: 700; font-size: 20px"
                  class="white--text pt-1"
                >
                  {{
                    locale == "en"
                      ? order.cityPassTitleEn
                      : order.cityPassTitleEs
                  }}
                </span>
              </v-row>
            </v-col>
            <v-col cols="2">
              <img
                style="cursor: pointer"
                class="mt-2"
                src="@/assets/delete-icon-cart.svg"
                alt="delete"
                @click="$emit('deleteCitypassFromCart', order.uuid)"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="pb-2" justify="center">
            <v-col cols="10" class="px-5">
              <span class="white--text">
                {{
                  new Date(
                    order.started_at
                  ).toLocaleDateString("en-gb")
                }}
                -
                {{
                  order.end_date.toLocaleDateString("en-gb")
                }}
              </span>
            </v-col>
          </v-row>
        </div>
        <v-row no-gutters align="start" style="margin-top: 10px">
          <v-col class="px-3">
            <v-col>
              <v-row
                align="center"
                v-for="number in order.number"
                :key="number.id"
                class="mt-1 mb-auto"
                :class="{ 'mb-2': !mazatlan }"
                :style="
                  number.type == 'Kids' && mazatlan ? 'padding-top: 10px;' : ''
                "
              >
                <v-col cols="6" class="py-0 mb-2 px-0">
                  <span class="textStyle">{{
                    number.type == "Adult"
                      ? "attractions_adult"
                      : "cart_label_child" | localize
                  }}</span
                  ><br />
                  <span
                    class="textStyle"
                    v-if="mazatlan"
                    style="font-weight: 400"
                  >
                    MX$
                    {{
                      new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                      }).format(number.currentPrice)
                    }}</span
                  > </v-col
                ><v-row no-gutters align="center" justify="end">
                  <img
                    src="@/assets/minus.svg"
                    alt="minus"
                    style="cursor: pointer"
                    @click="
                      number.number > 1
                        ? number.number--
                        : number.type == 'Adult'
                        ? (number.number = 1)
                        : (number.number = 0),
                        changeSubtotal()
                    "
                    v-if="
                      number.type == 'Adult'
                        ? number.number > 1
                        : number.number > 0
                    "
                  />
                  <img
                    v-else
                    src="@/assets/minus-disabled.svg"
                    alt="minus-disabled"
                    style="cursor: pointer"
                    @click="
                      number.number > 1
                        ? number.number--
                        : number.type == 'Adult'
                        ? (number.number = 1)
                        : (number.number = 0),
                        changeSubtotal()
                    "
                  />
                  <div
                    style="width: 40px; display: flex; justify-content: center"
                  >
                    <span class="textStyle">{{ number.number }}</span>
                  </div>
                  <img
                    src="@/assets/plus.svg"
                    alt="plus"
                    style="cursor: pointer"
                    @click="number.number++, changeSubtotal()"
                    v-if="
                      (number.type == 'Adult' &&
                        number.number < number.maxAdultAvailable) ||
                      (number.type == 'Kids' &&
                        number.number < number.maxChildAvailable)
                    "
                  />
                  <img
                    v-else
                    src="@/assets/plus-disabled.svg"
                    alt="plus"
                    style="cursor: pointer"
                  />
                </v-row>
              </v-row>
              <v-row class="mt-1 mb-3">
                <output class="textStyle">{{
                  "infants_label" | localize
                }}</output>
              </v-row>
              <v-row class="py-0 mt-2" v-if="mazatlan">
                <v-col cols="8" class="px-0 py-0">
                  <label style="font-size: 16px; color: #212121">
                    {{ "banking_charges_label" | localize }}</label
                  >
                </v-col>
                <v-col cols="4" class="px-0 py-0" style="text-align: end">
                  <label style="font-size: 15px"
                    >MX$
                    {{
                      new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                      }).format(order.fee)
                    }}</label
                  >
                </v-col>
              </v-row>
              <v-row
                style="margin-top: 15px"
                class="py-0"
                v-if="order.additional && order.additional !== 0"
              >
                <v-col
                  :class="mazatlan ? 'col-6 px-0 py-0' : 'col-12 px-0 py-0'"
                >
                  <label style="font-size: 16px; color: #212121">
                    {{ `${mazatlan ? "mzt_" : ""}bus_metro_label` | localize
                    }}<br />{{ "public_transport_label2" | localize }}</label
                  >
                </v-col>
                <v-col
                  v-if="mazatlan"
                  cols="5"
                  class="px-0 py-0"
                  style="text-align: end"
                >
                  <label style="font-size: 15px"
                    >MX$
                    {{
                      new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                      }).format(order.additional)
                    }}</label
                  >
                </v-col>
              </v-row>
              <v-row
                class="py-0"
                style="margin-top: 15px"
                :class="{ 'pt-4': !mazatlan }"
              >
                <v-col
                  class="py-0 mt-0 px-0"
                  style="font-weight: 500; font-size: 16px; color: #212121"
                >
                  <!-- <span>{{ "attractions_starting_date" | localize }}</span
                  ><br /> -->
                  <!-- <span>Booking Fee</span><br /> -->
                  <span class="font-weight-bold">{{
                    "cart_label_total_cost" | localize
                  }}</span>
                </v-col>
                <v-col
                  class="py-0 mt-0 px-0"
                  style="
                    font-weight: 500;
                    font-size: 16px;
                    color: #212121;
                    text-align: end;
                  "
                >
                  <!-- <span>{{
                    new Date(order.started_at).toLocaleDateString("en-EN", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }) 
                  }}</span
                  ><br /> -->
                  <!-- <span>MX$ {{ new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                        }).format(bookingFee)}}</span
                    ><br /> -->
                  <span class="font-weight-bold">
                    MX$
                    {{
                      new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                      }).format(order.subtotal)
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-divider
              style="opacity: 0.25; border: 1px solid #212121; margin-top: 10px"
              class="px-0 mt-5"
            />
            <v-col align-self="center" class="px-0">
              <v-row no-gutters class="mb-2">
                <img src="@/assets/ticket_icon.svg" alt="ticket" />
                <v-col cols="10">
                  <h3
                    class="ml-1"
                    style="color: #e62076; font-weight: 700; font-size: 20px"
                  >
                    {{ "cart_label_free_attractions" | localize }}
                  </h3>
                </v-col>
              </v-row>
            </v-col>
            <div class="mb-2">
              <div v-for="(attract, i) in order.attractions" :key="i">
                <v-row no-gutters class="px-0 mx-0" justify="start">
                  <div style="width: 30px">
                    <span
                      style="
                        font-weight: 500;
                        font-size: 16px;
                        color: black;
                        text-align: left;
                      "
                      class="ml-1"
                      >{{ i + 1 }}.</span
                    >
                  </div>
                  <div style="width: 88%">
                    <span
                      style="
                        font-weight: 500;
                        font-size: 16px;
                        color: black;
                        text-align: left;
                      "
                      >{{ attract.title }}</span
                    >
                  </div>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </div>
</template>

<script>
import AttractionsService from "../../requests/Attractions/AttractionsService";
import cityMixin from "@/mixins/cityMixin";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";

export default {
  data: () => ({
    cart: [],
    attraction: [],
    attractionUn: [],
    bookingFee: 0,
  }),
  props: {
    cartOrder: {
      require: true,
    },
  },
  mixins: [cityMixin, userSettingsMixin],
  mounted() {
    this.cart = this.cartOrder;
    this.filter();
  },
  methods: {
    changeSubtotal() {
      this.$emit("changeSubtotal", this.cart);
    },
    async getAttraction(uid) {
      let response = await AttractionsService.getAttraction(uid);
      return response.result;
    },
    async filter() {
      for (let index = 0; index < this.cart.length; index++) {
        let attraction = [];
        let uids = [];
        for (let i = 0; i < this.cart[index].attractions.length; i++) {
          if (this.cart[index].attractions[i].is_additional == "0") {
            uids.push(this.cart[index].attractions[i].uuid);
          }
        }
        let uidsStr = String(uids);
        if (uids.length > 0) {
          await AttractionsService.getAttractionsByUids(uidsStr).then((res) => {
            if (res.result !== null) {
              attraction = res.result;
              this.cart[index].attractions = attraction.sort(function (a, b) {
                var attractionTitleA = a.title.toUpperCase();
                var attractionTitleB = b.title.toUpperCase();
                return attractionTitleA < attractionTitleB
                  ? -1
                  : attractionTitleA > attractionTitleB
                  ? 1
                  : 0;
              });
            }
          });
        }
      }
    },
  },
  watch: {
    cartOrder: {
      deep: true,
      handler() {
        this.cart = this.cartOrder;
      },
    },
  },
};
</script>

<style>
.textStyle {
  font-weight: 700;
  font-size: 14px;
}
.cart_mobile {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 290px;
  min-height: 500px;
}
.header_mobile {
  height: max-content;
  width: 290px;
  align-content: center;
  border-radius: 10px 10px 0px 0px;
}
</style>