<template>
  <v-row justify="center" no-gutters>
    <span class="pr-5">{{
      "reset_password_label_open_email_for_code" | localize
    }}</span>
    <v-container>
      <v-row justify="center" class="mt-2">
        <v-col cols="12">
          <v-otp-input
            length="6"
            v-model.trim="code"
            type="number"
            class=""
            autofocus
            @input="message = ''"
          ></v-otp-input>
          <span style="color: #c92424">{{ message }}</span>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="">
      <v-row justify="space-between">
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          :style="
            $vuetify.breakpoint.xs ? 'text-align: center' : 'text-align: left'
          "
        >
          <v-btn color="blue darken-1" text @click="resendCode">
            {{ "sign_up_button_resend_code" | localize }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          v-if="!colorBtn"
          :style="
            $vuetify.breakpoint.xs ? 'text-align: center' : 'text-align: right'
          "
        >
          <v-btn
            :style="'background: #6ABAA4'"
            class="br-10 white--text"
            text
            @click="checkEmail()"
          >
            {{ "cart_details_button_next" | localize }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          v-if="colorBtn"
          :style="
            $vuetify.breakpoint.xs ? 'text-align: center' : 'text-align: right'
          "
        >
          <v-btn
            :style="'background: ' + colorBtn"
            class="br-10 white--text"
            text
            @click="checkEmail()"
          >
            {{ "cart_details_button_next" | localize }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import AuthService from "@/requests/Auth/AuthService";
export default {
  data: () => ({
    code: null,
    message: "",
  }),
  props: {
    colorBtn: {
      require: false,
    },
  },
  methods: {
    async checkEmail() {
      if (this.code !== null) {
        this.$emit("showLoader");
        let form = new FormData();
        form.append("code", this.code.toString());
        await AuthService.checkCode(form)
          .then(() => {
            AuthService.acceptAgreement().then(() => {
              this.$emit("to_success");
              this.$emit("success");
            });
          })
          .catch((e) => {
            this.$emit("hideLoader");
            this.message = e.response.data.message;
          });
      } else {
        this.message = "Please input code";
      }
    },
    async resendCode() {
      this.dialog = false;
      await AuthService.resendCode();
    },
  },
};
</script>

<style>
</style>