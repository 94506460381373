import mzt from '@/cities/mazatlan.json'
import gdl from '@/cities/guadalajara.json'

const cities = {
    'Guadalajara' : gdl,
    'Mazatlan' : mzt,
}

export default function cityFilter(key) {
    let city = 'Guadalajara'
    if (process.env.VUE_APP_TYPE && process.env.VUE_APP_TYPE == 'Guadalajara') {
        city = 'Guadalajara'
    }
    else if(process.env.VUE_APP_TYPE && process.env.VUE_APP_TYPE == 'Mazatlan'){
        city = 'Mazatlan'
    }
    return cities[city][key]
}