<template>
  <div>
    <v-dialog
      v-model="loginModal"
      persistent
      :max-width="!login && !code && success ? '400px' : '400px'"
    >
      <v-card class="pa-3" style="position: relative">
        <v-icon
          style="position: absolute; right: 12px"
          large
          @click="closeModal"
          >mdi-close</v-icon
        >
        <login-main
          @close_modal="closeModal()"
          @to_reg="toRegistration()"
          @to_code="toCode"
          v-if="login && !code"
        />
        <registration-main
          @close_modal="closeModal()"
          @to_login="toLogin()"
          @to_code="toCode()"
          v-if="!login && !code && !success"
        />
        <email-check @to_success="toSuccess()" class="pa-3" v-if="!login && code" />
        <registration-success
          class="pa-15"
          @to_login="toLogin()"
          v-if="!login && !code && success"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoginMain from "../Login/LoginMain.vue";
import RegistrationMain from "../Registration/RegistrationMain.vue";
import EmailCheck from "../Registration/EmailCheckComponent.vue";
import RegistrationSuccess from "../Registration/RegistrationSuccess.vue";
export default {
  components: {
    LoginMain,
    RegistrationMain,
    EmailCheck,
    RegistrationSuccess,
  },
  data: () => ({
    loginModal: true,
    login: true,
    code: false,
    success: false,
  }),
  beforeDestroy() {
    this.closeModal();
  },
  methods: {
    closeModal() {
      this.loginModal = false;
      this.$emit("close_modal");
    },
    toRegistration() {
      this.login = false;
    },
    toLogin() {
      this.success = false;
      this.code = false;
      this.login = true;
    },
    toCode() {
      this.success = false;
      this.code = true;
      this.login = false;
    },
    toSuccess() {
      this.success = true;
      this.code = false;
      this.login = false;
    },
  },
  loginModal: {
    handler() {
      this.$emit("close_modal");
    },
  },
};
</script>

<style>
/* .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
} */
</style>