<template>
  <div>
    <v-container class="header_container">
      <v-app-bar class="header_app_bar mt-2" elevation="0">
        <v-toolbar-items style="width: 100%">
          <v-row
            no-gutters
            align="center"
            style="position: relative"
            :justify="isMobile ? 'space-between' : 'start'"
          >
            <v-col
              class="col-lg-1 col-3"
              :style="
                !$vuetify.breakpoint.lg && !$vuetify.breakpoint.xlg
                  ? 'padding: 0px !important; cursor: pointer'
                  : $route.name == 'support' || $route.name == 'prebooking'
                  ? ''
                  : 'cursor: pointer'
              "
            >
              <v-row class="mt-1 mt-lg-0 ml-0 ml-lg-0" no-gutters>
                <img
                  :src="
                    require('@/assets/' + $options.filters.city('main_logo'))
                  "
                  class="main-img-logo"
                  :class="{ 'main-img-logo-mazatlan': mazatlan }"
                  @click="pushHome"
                  style="z-index: 10"
                  :style="
                    $route.name == 'support' || $route.name == 'prebooking'
                      ? 'pointer-events:none;'
                      : ''
                  "
                />
              </v-row>
            </v-col>
            <v-col
              class="col-md-8 col-1 d-none d-lg-block"
              v-if="$route.name !== 'support' && $route.name !== 'prebooking'"
            >
              <v-row no-gutters :class="{ 'px-15 links-block': !mazatlan }">
                <router-link
                  :to="!isMobile ? '/city_passes' : '/mobile/city_passes'"
                >
                  <v-btn text class="header_button" x-small
                    >{{ "header_city_passes_part1" | localize }}<br />
                    <!-- {{ "header_city_passes_part2" | localize }} --></v-btn
                  >
                </router-link>
                <router-link to="/attractions" v-if="!isMobile">
                  <v-btn text class="header_button"
                    ><span>
                      {{ "header_our_attractions_part1" | localize }}
                      <!-- <br />
                      {{ "header_our_attractions_part2" | localize }} -->
                    </span></v-btn
                  >
                </router-link>
                <router-link to="/hoozie_rewards" v-if="isMobile || mazatlan">
                  <v-btn text class="header_button" x-small>
                    {{ "header_hoozie_rewards_part1" | localize }}<br />
                    {{ "header_hoozie_rewards_part2" | localize }}
                  </v-btn>
                </router-link>
                <router-link to="/where_to_stay/all">
                  <v-col cols="4"
                    ><v-btn text class="header_button" x-small>
                      <span>
                        {{ "header_stay_eat_shop_part1" | localize }}<br />
                        {{ "header_stay_eat_shop_part2" | localize }}</span
                      ></v-btn
                    >
                  </v-col>
                </router-link>
                <router-link to="/faq">
                  <v-btn text class="header_button" x-small>
                    {{ "header_faq_part1" | localize }}<br />
                    {{ "header_faq_part2" | localize }}
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
            <v-col
              class="col-3 d-none d-lg-block"
              v-if="$route.name !== 'support' && $route.name !== 'prebooking'"
            >
              <v-row no-gutters justify="end" align="center">
                <LanguageDropDown />
                <ProfileDropDown />
                <v-badge
                  color="error"
                  :content="count"
                  :value="count && count != '0' ? true : false"
                  overlap
                  class="mt-0 mx-lg-0 mx-auto"
                >
                  <v-icon class="ml-5 mt-0" @click="openCart()" color="#e62076"
                    >mdi-cart-outline</v-icon
                  >
                </v-badge>
                <cartComponent
                  @close="closeCart"
                  :key="cartKey"
                  v-if="isCart"
                  @get_cart="getCart"
                />
                <v-btn
                  @click="pushBuy"
                  color="#e62076"
                  dark
                  width="92px"
                  style="text-transform: unset !important; height: 49px"
                  class="ml-6"
                  v-if="$route.name !== 'attractions'"
                >
                  {{ "header_button_buy" | localize }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              style="font-size: 22px; color: #212121; font-weight: 700"
              class="col-9 pl-15 d-none d-lg-block"
              v-if="$route.name == 'prebooking'"
            >
              <div style="padding-left: 55px;" class="ml-15" v-if="$route.name == 'prebooking'">
                {{ "admin_booking_configurator_label" | localize }}
              </div>
            </v-col>
            <v-col
              class="col-2 d-none d-lg-block"
              v-if="$route.name == 'prebooking'"
            >
              <v-row no-gutters justify="end" align="center">
                <LanguageDropDown />
              </v-row>
            </v-col>
            <v-col
              class="d-block d-lg-none px-0"
              style="position: absolute; transform: translate(calc(46vw - 50%))"
            >
              <v-row no-gutters align="center" justify="center">
                <!-- JOEL CORRECTIONS
                   <v-btn
                  v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xlg"
                  @click="pushBuy"
                  color="#e62076"
                  dark
                  width="92px"
                  class="ml-5"
                  style="text-transform: unset !important; height: 38px"
                >
                  {{ "header_button_buy" | localize }}
                </v-btn> -->
                <div>
                  <v-badge
                    color="error"
                    :content="count"
                    :value="count && count != '0' ? true : false"
                    overlap
                    class="mt-1 mx-lg-0 mx-auto"
                  >
                    <v-icon @click="(isCart = true), cartKey++" color="#e62076"
                      >mdi-cart-outline</v-icon
                    >
                  </v-badge>
                  <v-btn
                    v-if="
                      ($vuetify.breakpoint.lg || $vuetify.breakpoint.xlg) &&
                      $route.name !== 'attractions'
                    "
                    @click="pushBuy"
                    color="#e62076"
                    dark
                    width="92px"
                    style="text-transform: unset !important; height: 49px"
                    class="ml-6"
                  >
                    {{ "header_button_buy" | localize }}
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar-items>
        <v-btn
          icon
          v-if="!drawer"
          @click="drawer = true"
          class="d-flex d-lg-none mt-1"
          ><img src="@/assets/menu_icon.svg" width="20px" />
        </v-btn>
        <v-btn
          icon
          v-if="drawer"
          @click="drawer = false"
          class="d-flex d-lg-none mt-1"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-expand-transition>
        <v-navigation-drawer
          v-model="drawer"
          hide-overlay
          right
          temporary
          absolute
          app
          style="
            z-index: 100;
            width: 100% !important;
            top: 105px !important;
            box-shadow: none !important;
          "
        >
          <v-container>
            <v-row no-gutters class="mt-3" v-if="!isMobile">
              <router-link to="/attractions">
                <v-btn text class="mobile_button">
                  {{ "header_our_attractions_part1" | localize }}
                  {{ "header_our_attractions_part2" | localize }}</v-btn
                >
              </router-link>
            </v-row>
            <v-row no-gutters class="mt-7">
              <router-link
                :to="!isMobile ? '/city_passes' : '/mobile/city_passes'"
              >
                <v-btn text class="mobile_button"
                  >{{ "header_city_passes_part1" | localize }}
                  <!-- {{ "header_city_passes_part2" | localize }} -->
                </v-btn>
              </router-link>
            </v-row>
            <v-row no-gutters class="mt-7" v-if="isMobile || mazatlan">
              <router-link to="/hoozie_rewards">
                <v-btn text class="mobile_button">
                  {{ "header_hoozie_rewards_part1" | localize }}
                  {{ "header_hoozie_rewards_part2" | localize }}
                </v-btn>
              </router-link>
            </v-row>
            <v-row no-gutters class="mt-7">
              <router-link
                :to="{ name: 'WhereToStay', params: { selected: 'all' } }"
              >
                <v-btn text class="mobile_button text-left" height="100%">
                  <div>
                    {{ "header_stay_eat_shop_part1" | localize }}<br /><span>{{
                      "header_stay_eat_shop_part2" | localize
                    }}</span>
                  </div></v-btn
                >
              </router-link>
            </v-row>
            <v-row no-gutters class="mt-7">
              <router-link to="/faq">
                <v-btn text class="mobile_button">
                  {{ "header_faq_part1" | localize }}
                  {{ "header_faq_part2" | localize }}
                </v-btn>
              </router-link>
            </v-row>
            <v-row no-gutters class="mt-9">
              <v-divider />
            </v-row>
            <v-row
              no-gutters
              class="mt-4 text-center"
              justify="center"
              align="center"
            >
              <v-badge
                bordered
                color="green"
                dot
                :value="loggedUser ? true : false"
                overlap
                ><v-icon color="#e62076"
                  >mdi-account-circle-outline</v-icon
                ></v-badge
              >
              <span
                v-if="!loggedUser"
                class="login ml-2"
                @click="openModal()"
                style="
                  font-style: normal;
                  font-weight: 500;
                  font-size: 22px;
                  line-height: 29px;
                  color: #212121;
                  cursor: pointer;
                "
                >{{ "header_login_signUp_label" | localize }}
              </span>
              <router-link
                v-if="loggedUser"
                class="login ml-2"
                :to="'/profile'"
                style="
                  font-style: normal;
                  font-weight: 500;
                  font-size: 22px;
                  line-height: 29px;
                  color: #212121;
                "
                >My Account
              </router-link>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-divider />
            </v-row>
            <v-row no-gutters class="mt-4 ml-2 text-center" justify="center">
              <LanguageDropDown
                v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xlg"
              />
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-divider />
            </v-row>
            <v-row no-gutters class="mt-4 text-center" justify="center">
              <v-row justify="center" align="center" class="mt-4">
                <v-col
                  class="col-12 mb-4"
                  style="font-weight: 600; font-size: 16px; line-height: 20px"
                  >{{ "hoozie_rewards_label_download_app" | localize }}
                </v-col>
                <v-col class="col-6">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hoozaroundgdlcampus.domila"
                    target="_blank"
                  >
                    <img
                      src="@/assets/downloadPlayMarket.svg"
                      style="width: 147px"
                    />
                  </a>
                </v-col>
                <v-col class="col-6">
                  <a
                    href="https://apps.apple.com/us/app/hoozie/id1488219149"
                    target="_blank"
                  >
                    <img
                      src="@/assets/downloadAppleStore.svg"
                      style="width: 147px"
                    />
                  </a>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-navigation-drawer>
      </v-expand-transition>
    </v-container>
    <login-modal v-if="loginModal" @close_modal="closeModal" />
    <!-- <announcement-modal
      :visible="announcementModal"
      v-if="announcementModal"
      @close="announcementModal = false"
    /> -->
  </div>
</template>

<script>
import LanguageDropDown from "./UI/LanguageDropDown.vue";
import ProfileDropDown from "./UI/ProfileDropDown.vue";
import cartComponent from "./Cart/cartComponent.vue";
import loginModal from "./UI/LoginModal.vue";
import { mapGetters } from "vuex";
import cityMixin from "@/mixins/cityMixin";
import mobile from "@/mixins/mobileMixin";
//import announcementModal from "@/components/UI/announcementModal.vue";
import serverMixin from "@/mixins/serverMixin";

export default {
  components: {
    LanguageDropDown,
    ProfileDropDown,
    cartComponent,
    loginModal,
    //announcementModal,
  },
  mixins: [cityMixin, mobile, serverMixin],
  data: () => ({
    isCart: false,
    cartKey: 0,
    count: 0,
    drawer: false,
    loginModal: false,
    //announcementModal: false,
  }),
  props: {
    showCart: {
      require: true,
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  mounted() {
    setInterval(() => {
      this.count = localStorage.getItem("count");
    }, 1000);
  },
  watch: {
    drawer: {
      handler() {
        this.$emit("display", this.drawer);
      },
    },
    showCart: {
      handler() {
        if (this.showCart == true) {
          this.isCart = true;
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.loginModal = false;
    },
    openModal() {
      this.drawer = false;
      this.loginModal = true;
    },
    storageListener() {
      this.count = localStorage.count;
    },
    pushProfile() {
      this.$router.push("profile");
    },
    openCart() {
      this.isCart = false;
      setTimeout(() => {
        this.isCart = true;
      }, 20);

      this.cartKey++;
    },
    pushCart() {
      this.isCart = false;
      setTimeout(() => {
        this.isCart = !this.isCart;
      }, 20);
    },
    pushHome() {
      this.$router.push("/");
    },
    pushBuy() {
      if (!this.isMobile) {
        this.$router.push("/attractions");
      } else {
        this.$router.push({ name: "mobile_citypass" });
      }
      // if (this.isProd && !this.mazatlan) {
      //   this.announcementModal = true;
      // } else {
      //   if (!this.isMobile) {
      //     this.$router.push("/attractions");
      //   } else {
      //     this.$router.push({ name: "mobile_citypass" });
      //   }
      // }
    },
    getCart(count) {
      this.count = count;
    },
    closeCart() {
      this.$emit("closeCart");
      this.isCart = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.header_container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.col {
  padding: 0px !important;
}
.links-block {
  justify-content: space-between !important;
  padding-left: 110px !important;
}
.header_app_bar {
  height: 62px !important;
  background-color: #fff !important;
}

.header_button {
  text-transform: unset !important;
  height: 50px !important;
  padding: 0 10px !important;
  font-weight: 500 !important;
  font-size: 14.5px !important;
  line-height: 20px !important;
}
.router-link-exact-active {
  border-bottom: 3px #e62076 solid !important;
  font-weight: 700 !important;

  .header_button {
    font-weight: 600;
    color: #e62076;
  }
}
a {
  font-size: 20px;
  text-decoration: none;
}
.mobile_button {
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #212121;
  height: 29px;
  text-transform: unset !important;
  float: left;
  padding: 0px !important;
}
.v-btn__content {
  top: 20px !important;
}
@media (max-width: 1263px) {
  .mobile_button:hover {
    background-color: transparent !important;
  }
  .header_app_bar {
    height: 60px !important;
  }
  .router-link-exact-active {
    border-bottom: none !important;
    font-weight: 500 !important;

    .mobile_button {
      font-weight: 700;
      color: #e62076 !important;
    }
  }
  .main-img-logo {
    width: 140px !important;
    &-mazatlan {
      width: 66px !important;
    }
  }
}
.main-img-logo {
  width: 180px;
  max-height: 62px;
  &-mazatlan {
    width: 75px;
    max-height: 75px;
  }
}
</style>