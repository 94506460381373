<template>
  <div>
    <v-row justify="center">
      <v-col style="text-align: center">
        <div>
          <img src="@/assets/RegistrationSuccess.png" alt="checkoutSuccess" />
        </div>
        <div class="my-5">{{'sign_up_label_account_activated' | localize}}</div>
        <v-row justify="center" align="center">
        <span>{{'reset_password_label_now_you_can' | localize}}</span>
        <span
          ><v-btn color="#e62076" class="pa-0 mb-0" dark text @click="$emit('to_login')"><strong>{{'sign_in_heading_sign_in' | localize}}</strong></v-btn></span
        >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>