export default {
    data: () => ({
    }),
    computed: {
        mazatlan() {
            if (process.env.VUE_APP_TYPE == 'Mazatlan') {
                return true
            }
            else return false
        },
        guadalajara() {
            if (process.env.VUE_APP_TYPE == 'Guadalajara') {
                return true
            }
            else return false
        },
    }
}