import store from '../store'
import en from '@/locales/en.json'
import es from '@/locales/es.json'

const locales = {
    'en' : en,
    'es' : es
}

export default function localizeFilter(key) {
    let locale = "es"
    if (store.getters.loggedUser) {
        locale = store.getters.loggedUser.locale || "es"
    }
    else {
        locale = store.getters.user.locale || "es"
    }
    return locales[locale][key]
}