import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Vuelidate from 'vuelidate'
import store from './store';
import localizeFilter from './filters/localize.filter'
import cityFilter from './filters/city.filter'
import VueSocialChat from 'vue-social-chat'
import Vidle from 'v-idle'

Vue.config.productionTip = false

Vue.filter('localize', localizeFilter)
Vue.filter('city', cityFilter)
Vue.use(CKEditor)
Vue.use(Vidle)
Vue.use(VueSocialChat)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDjv7izdGTG6V_5XF7VwqJOzbjEYYiJjKc',
    libraries: 'places',
    region: 'ES',
    language: store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale,
  }
})
Vue.use(VueMask);
Vue.filter('localize', localizeFilter)
Vue.use(Vuelidate)
export const eventBus = new Vue()
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
