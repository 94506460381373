<template>
  <v-container align="center" justify="center" style="max-width: 600px">
    <v-col>
      <p
        class="text-center mb-3"
        style="font-weight: 600; font-size: 32px; line-height: 39px"
        cols="12"
      >
        {{ "sign_in_button_sign_up" | localize }}
      </p>
    </v-col>
    <v-form @submit.prevent="registration">
      <v-row no-gutters>
        <v-col cols="12">
          <span class="label-register">{{
            "sign_up_placeholder_first_name" | localize
          }}</span
          ><span class="req"> *</span>
          <v-text-field
            outlined
            dense
            :hide-details="!firstNameError.length"
            :error-messages="firstNameError | localize"
            background-color="white"
            class="mt-1 mb-2"
            v-model.trim="user.firstName"
            @blur="$v.user.firstName.$touch()"
          />
        </v-col>
      </v-row>
      <v-row no-gutters
        ><v-col cols="12">
          <span class="label-register">{{
            "sign_up_placeholder_last_name" | localize
          }}</span
          ><span class="req"> *</span>
          <v-text-field
            outlined
            class="iconPass mb-2 mt-1"
            :error-messages="lastNameError | localize"
            :hide-details="!lastNameError.length"
            dense
            background-color="white"
            v-model.trim="user.lastName"
            @blur="$v.user.lastName.$touch()"
          /> </v-col
      ></v-row>
      <v-row
        justify="space-between"
        no-gutters
        :align="userLocale.locale == 'es' ? 'end' : 'start'"
      >
        <v-col cols="4">
          <span class="label-register">{{
            "sign_up_placeholder_phone_code" | localize
          }}</span>
          <span class="req"> *</span>
          <v-text-field
            outlined
            class="iconPass mr-4 mt-1 mb-2"
            :hide-details="!phoneCodeError.length"
            :maxLength="
              user.phoneNumber && user.phoneNumber.length > 12
                ? 16 - user.phoneNumber.length
                : 5
            "
            v-mask="'+####'"
            @keypress="isNumber($event)"
            :error-messages="phoneCodeError | localize"
            dense
            background-color="white"
            v-model.trim="user.phoneCode"
            @blur="$v.user.phoneCode.$touch()"
          />
        </v-col>
        <v-col cols="8">
          <span class="label-register">{{
            "sign_up_placeholder_phone_number" | localize
          }}</span>
          <span class="req"> *</span>
          <v-text-field
            outlined
            class="iconPass mt-1 mb-2"
            @keypress="isNumber($event)"
            :maxLength="
              user.phoneCode.length > 1
                ? 16 - user.phoneCode.length
                : 15 - user.phoneCode.length
            "
            :hide-details="!phoneNumberError.length && !authPhoneError.length"
            dense
            background-color="white"
            v-mask="`${phoneNumberMask}`"
            @input="authPhoneError = []"
            :error-messages="
              phoneNumberError.length > 0
                ? phoneNumberError
                : authPhoneError[0] | localize
            "
            v-model.trim="user.phoneNumber"
            @blur="$v.user.phoneNumber.$touch()"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <span class="label-register">{{
            "faq_placeholder_email" | localize
          }}</span
          ><span class="req"> *</span>
          <v-text-field
            outlined
            dense
            class="mt-1 mb-2"
            :error-messages="
              emailError.length > 0 ? emailError : authEmailError[0] | localize
            "
            @input="authEmailError = []"
            :hide-details="!emailError.length && !authEmailError.length"
            background-color="white"
            v-model.trim="user.email"
            @blur="$v.user.email.$touch()"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <span class="label-register">{{
            "sign_in_placeholder_password" | localize
          }}</span
          ><span class="req"> *</span>
          <v-text-field
            outlined
            dense
            :error-messages="passwordError | localize"
            v-bind="attrs"
            v-on="on"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordVisible = !passwordVisible"
            :type="passwordVisible ? 'text' : 'password'"
            :hide-details="!passwordError.length"
            background-color="white"
            class="mt-1 mb-2"
            v-model.trim="user.password"
            @blur="$v.user.password.$touch()"
          />
        </v-col>
      </v-row>
      <v-row justify="space-between" class="pt-0" no-gutters>
        <v-col cols="6">
          <v-menu
            ref="menu"
            v-model="menu"
            size="small"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="pa-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="label-register">{{
                "sign_up_placeholder_birth_date" | localize
              }}</span>
              <v-text-field
                class="pa-0 mt-1 mb-2 mr-2"
                dense
                :value="
                  user.birthDate
                    ? new Date(
                        user.birthDate.substr(0, 4),
                        user.birthDate.substr(6, 1) - 1,
                        user.birthDate.substr(8, 2)
                      ).toLocaleDateString('en-gb', {
                        timeZone: 'Europe/London',
                      })
                    : ''
                "
                prepend-icon="mdi-calendar"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                @click:prepend="on.click"
              ></v-text-field>
            </template>
            <v-date-picker
              :show-current="
                new Date(new Date().setFullYear(new Date().getFullYear() - 20))
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(new Date().setFullYear(new Date().getFullYear() - 16))
                  .toISOString()
                  .substr(0, 10)
              "
              :min="
                new Date(new Date().setFullYear(new Date().getFullYear() - 100))
                  .toISOString()
                  .substr(0, 10)
              "
              v-model="user.birthDate"
              scrollable
              :locale="userLocale.locale"
              ref="picker"
              @change="$refs.menu.save(user.birthDate), (menu = false)"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ "button_cancel" | localize }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(user.birthDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6">
          <span class="label-register ml-2">{{
            "sign_up_placeholder_gender" | localize
          }}</span>
          <v-select
            class="pa-0 mt-1 mb-2 ml-2"
            dense
            :items="Object.values(items)"
            :item-value="'value'"
            :item-text="'title'"
            outlined
            v-model.trim="user.gender"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <span class="label-register"
          >{{ "cart_details_placeholder_country" | localize }} </span
        ><span class="req">&nbsp;*</span>
        <v-col cols="12">
          <v-autocomplete
            outlined
            class="mt-1 iconPass mb-4"
            dense
            v-model="user.country"
            background-color="white"
            :items="Object.values(countryList)"
            :item-value="'id'"
            :item-text="'name'"
            :error-messages="countryError | localize"
            :hide-details="this.$v.user.country.required"
            @blur="$v.user.country.$touch()"
          >
            <!-- <template #selection="{ item }">
              <v-chip small dark color="#E62076">{{ item.name }}</v-chip>
            </template> -->
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-expand-transition v-if="isMexico">
        <v-row no-gutters class="mb-5" v-if="user.country">
          <span class="label-register">
            <!-- {{
            "sign_up_placeholder_hotel" | localize
          }} -->
            {{ "cart_details_placeholder_state" | localize }}
            <span class="req">*</span>
          </span>
          <v-col cols="12">
            <v-autocomplete
              v-if="isMexico"
              class="mt-1 iconPass"
              dense
              :items="Object.values(states)"
              :item-value="'id'"
              :item-text="'name'"
              :no-data-text="'not_available_hotels' | localize"
              :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
              outlined
              hide-details="auto"
              v-model.trim="userCountry.state"
              :error-messages="stateError | localize"
              @blur="$v.userCountry.state.$touch()"
            >
              <!-- <template #selection="{ item }">
               {{ item.shop_name }} <span class="req" v-if="isMexico"></span>
              </template> -->
            </v-autocomplete>
            <v-text-field
              class="mt-1 iconPass"
              dense
              outlined
              hide-details
              v-model.trim="userCountry.state"
              v-else
            />
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <v-row no-gutters class="mb-5" v-if="user.country">
          <span class="label-register">
            <!-- {{
            "sign_up_placeholder_hotel" | localize
          }} -->
            {{ "sign_up_city_placeholder" | localize }}
            <span class="req" v-if="isMexico">*</span>
          </span>
          <v-col cols="12">
            <v-text-field
              class="mt-1 iconPass"
              dense
              outlined
              hide-details="auto"
              v-model.trim="userCountry.city"
              @blur="$v.userCountry.city.$touch()"
              :error-messages="
                isMexico ? $options.filters.localize(cityError) : ''
              "
            >
              <!-- <template #selection="{ item }">
                <v-chip small dark color="#E62076">{{ item.shop_name }}</v-chip>
              </template> -->
            </v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <v-row no-gutters class="mb-5" v-if="!localUser">
          <span class="label-register">
            {{ "sign_up_placeholder_hotel" | localize }}
          </span>
          <v-col cols="12">
            <v-autocomplete
              class="mt-1 iconPass"
              dense
              :items="Object.values(hotels)"
              :item-value="'uid'"
              :item-text="'shop_name'"
              :no-data-text="'not_available_hotels' | localize"
              :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
              outlined
              hide-details
              v-model.trim="user.hotel"
            >
              <!-- <template #selection="{ item }">
                <v-chip small dark color="#E62076">{{ item.shop_name }}</v-chip>
              </template> -->
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-col class="col-12">
        <v-row justify="center" align="center">
          <img
            class="mr-1"
            src="@/assets/notChecked.svg"
            style="cursor: pointer"
            v-if="isAgree == 0"
            @click="isAgree = 1"
          />
          <img
            src="@/assets/chekedTerm.svg"
            v-if="isAgree == 1"
            class="mr-1"
            @click="isAgree = 0"
            style="cursor: pointer"
          />

          <span style="font-weight: 500; font-size: 14px; line-height: 17px"
            >{{ "cart_details_label_agree_policy1" | localize }}
            <span
              @click="openModal()"
              style="
                color: black;
                font-weight: 700;
                text-decoration: underline;
                cursor: pointer;
              "
            >
              {{ "license_short_label" | localize }}</span
            >
          </span>
        </v-row>
      </v-col>
      <v-col class="text-center mt-5" cols="12">
        <v-row>
          <v-btn
            color="#e62076"
            text
            style="text-transform: none !important"
            @click="$emit('to_login')"
          >
            {{ "sign_in_heading_sign_in" | localize }}
          </v-btn>
          <v-spacer />
          <v-btn
            :style="
              isAgree == 0
                ? 'text-transform: none !important;'
                : 'background: #6ABAA4;text-transform: none !important; border-bottom: 1px solid #4E8777;'
            "
            class="br-10 white--text"
            large
            type="submit"
            :disabled="isAgree == 0"
          >
            {{ "sign_in_button_sign_up" | localize }}
          </v-btn>
        </v-row>
      </v-col>
    </v-form>
    <terms-modal
      v-if="termsModal"
      :rules="'license'"
      @close_modal="closeModal"
    />
    <confirm-local-modal
      v-if="showConfirmLocalModal"
      :visible="showConfirmLocalModal"
      @choosed="choosedConfirm"
    />
  </v-container>
</template>

<script>
import AuthService from "@/requests/Auth/AuthService";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import termsModal from "@/components/UI/TermsModal.vue";
import WhereToStayService from "@/requests/WhereToStay/WhereToStayService";
import ListsService from "../../requests/Lists/ListsService";
import ConfirmLocalModal from "../UI/confirmLocalModal.vue";
export default {
  mixins: [validationMixin],
  components: {
    termsModal,
    ConfirmLocalModal,
  },
  data: () => ({
    user: {
      hotel: "",
      home: "",
      birthDate: "",
      phoneCode: "+",
      gender: "",
    },
    passwordVisible: false,
    loading: false,
    menu: false,
    code: 0,
    isAgree: -1,
    termsModal: false,
    localUser: true,
    showConfirmLocalModal: false,
    items: [],
    itemsEN: [
      { value: "Male", title: "Male" },
      { value: "Female", title: "Female" },
      { value: "Other", title: "Other" },
    ],
    itemsES: [
      { value: "Male", title: "Hombre" },
      { value: "Female", title: "Mujer" },
      { value: "Other", title: "Otro" },
    ],
    isShowResetDialog: false,
    authEmailError: [],
    authPhoneError: [],
    siteUser: "",
    phoneNumberMask: "",
    hotels: [],
    countryList: [],
    states: [],
    isMexico: false,
    userCountry: {
      state: "",
      city: "",
    },
  }),
  validations() {
    return {
      user: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        phoneCode: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(5),
        },
        phoneNumber: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(16 - this.user.phoneCode.length),
        },
        email: {
          required,
          email,
        },
        country: {
          required,
        },
        password: {
          required,
          minLength: minLength(8),
          validUppercase: function (value) {
            const containsUppercase = /[A-Z]/.test(value);
            return containsUppercase;
          },
          validLowercase: function (value) {
            const containsLowercase = /[a-z]/.test(value);
            return containsLowercase;
          },
          validNumber: function (value) {
            const containsNumber = /[0-9]/.test(value);
            return containsNumber;
          },
          validSpecial: function (value) {
            const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
            return containsSpecial;
          },
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /[0-9]/.test(value);
            const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
            return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial &&
              minLength
            );
          },
        },
      },
      userCountry: {
        state: {
          required,
        },
        city: {
          required,
        },
      },
    };
  },
  mounted() {
    this.isAgree = 0;
    this.getHotels();
    this.getCountry();
    let date = new Date();
    this.items = this.userLocale.locale == "en" ? this.itemsEN : this.itemsES;
    date.setFullYear(date.getFullYear() - 20);
    console.log(
      new Date(new Date().setFullYear(new Date().getFullYear() - 20))
        .toISOString()
        .substr(0, 10)
    );
  },
  methods: {
    isNumber(evt) {
      const isNumber = isFinite(evt.key);

      if (!isNumber) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getHotels() {
      let response = await WhereToStayService.getBusinessFromCategory(
        "28,183,384"
      );
      this.hotels = response.result;
      this.showLoader = false;
    },
    async getCountry() {
      let response = await ListsService.getCountry();
      this.countryList = response.data;
    },
    closeModal() {
      this.termsModal = false;
    },
    openModal() {
      this.termsModal = true;
    },
    ...mapActions(["updateInfo"]),
    registration() {
      this.$v.$touch();
      if (this.isMexico) {
        if (!this.$v.$invalid && this.phoneNumberError.length == 0) {
          this.onRegistration();
        }
      } else {
        if (!this.$v.user.$invalid && this.phoneNumberError.length == 0) {
          this.onRegistration();
        }
      }
    },
    async onRegistration() {
      console.log(this.user);
      let form = new FormData();
      form.append("email", this.user.email);
      form.append("password", this.user.password);
      form.append("first_name", this.user.firstName);
      form.append("last_name", this.user.lastName);
      form.append("phone_code", this.user.phoneCode.replace(/\D+/g, ""));
      form.append("phone_num", this.user.phoneNumber.replace(/\D+/g, ""));
      form.append("neighbourhood", 1);
      form.append("dob", this.user.birthDate);
      form.append("hotel", this.user.hotel);
      form.append("gender", this.user.gender);
      form.append("country", this.user.country);
      form.append("user_city", this.userCountry.city);
      form.append("state", this.userCountry.state);
      // form.append("isNewUser", false);
      form.append("lang", this.userLocale.locale);
      form.append("type", this.localUser ? "Local" : "Tourist");
      await AuthService.registration(form)
        .then((res) => {
          this.updateInfo({
            token: res.data.token,
            phoneCode: res.data.phone_code,
            phoneNumber: res.data.phone_num,
          });
          this.$emit("to_code");
          console.log(this.user);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 401) {
            if (
              e.response.data.message ==
              "This email has already been used in a previous registration. Please use another."
            )
              this.authEmailError.push("sign_up_email_already_used");
            else if (
              e.response.data.message ==
              "We are already have this phone number for someone else. Please provide another phone number."
            )
              this.authPhoneError.push("sign_up_phone_number_already_used");
          } else if (e.response.status == 422) {
            if (
              e.response.data.message ==
              "This email has already been used in a previous registration. Please use another."
            )
              this.authEmailError.push("sign_up_email_already_used");
            else if (
              e.response.data.message ==
              "We are already have this phone number for someone else. Please provide another phone number."
            )
              this.authPhoneError.push("sign_up_phone_number_already_used");
          }
          console.log(this.authPhoneError[0]);
        });

      // let EmailForm = new FormData();
      // form.append("email", this.user.email);
      // form.append("lang", "en");
      // var emailResponse = await AuthService.checkEmail(EmailForm);
      // console.log("check email", emailResponse);
    },

    async checkEmail() {
      console.log(this.code);
    },
    choosedConfirm(check) {
      this.localUser = check;
      if (check == true) {
        this.userCountry.state = this.states.filter(
          (state) => state.name == "Jalisco"
        )[0].id;
      }
      this.showConfirmLocalModal = false;
    },
    async getStates() {
      await ListsService.getState(this.user.country).then((res) => {
        this.states = res.data;
      });
    },
  },
  watch: {
    "user.country": {
      handler() {
        this.user.hotel = "";
        let country = this.countryList.filter(
          (country) => country.id == this.user.country
        );
        if (this.user.country !== undefined && this.user.country !== "") {
          if (country[0].have_states == "Yes") {
            this.getStates();
          }
          this.userCountry = {
            state: "",
            city: "",
          };
        }
        if (
          country[0].name.includes("México") ||
          country[0].name.includes("Mexico")
        ) {
          this.showConfirmLocalModal = true;
          this.isMexico = true;
        } else {
          this.localUser = false;
          this.isMexico = false;
        }
      },
    },
    "user.phone_code": {
      deep: true,
      handler() {
        let quantitySymbol =
          this.user.phoneCode.length > 1
            ? 16 - this.user.phoneCode.length
            : 15 - this.user.phoneCode.length;
        this.phoneNumberMask = "";
        for (let index = 0; index < quantitySymbol; index++) {
          this.phoneNumberMask += "#";
        }
      },
    },
    "userCountry.state": {
      handler() {
        let state = this.states.filter(
          (state) => state.id == this.userCountry.state
        )[0];
        if (state.name !== "Jalisco") {
          this.localUser = false;
        } else {
          this.localUser = true;
        }
      },
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters({ userLocale: "user" }),
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.user.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    firstNameError() {
      const errors = [];
      if (!this.$v.user.firstName.$dirty) {
        return errors;
      }
      !this.$v.user.firstName.required &&
        errors.push("sign_up_validation_first_name_required");
      return errors;
    },
    lastNameError() {
      const errors = [];
      if (!this.$v.user.lastName.$dirty) {
        return errors;
      }
      !this.$v.user.lastName.required &&
        errors.push("sign_up_validation_last_name_required");
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.user.phoneNumber.$dirty) {
        return errors;
      }
      !this.$v.user.phoneNumber.required &&
        errors.push("sign_up_validation_phone_number_required");
      if (
        !this.$v.user.phoneNumber.minLength ||
        !this.$v.user.phoneNumber.maxLength
      ) {
        errors.push("sign_up_validation_phone_number_incorrect");
      }
      return errors;
    },
    phoneCodeError() {
      const errors = [];
      if (!this.$v.user.phoneCode.$dirty) {
        return errors;
      } else if (!this.$v.user.phoneCode.required) {
        errors.push("sign_up_validation_phone_code_required");
        return errors;
      }
      if (!this.$v.user.phoneCode.maxLength) {
        errors.push("sign_up_validation_phone_number_incorrect");
      }
      if (!this.$v.user.phoneCode.minLength) {
        errors.push("sign_up_validation_phone_code_required");
      }
      return errors;
    },
    countryError() {
      const errors = [];
      if (!this.$v.user.country.$dirty) {
        return errors;
      }
      !this.$v.user.country.required &&
        errors.push("sign_up_validation_country_required");
      return errors;
    },
    stateError() {
      const errors = [];
      if (!this.$v.userCountry.state.$dirty) {
        return errors;
      }
      !this.$v.userCountry.state.required &&
        errors.push("sign_up_mexico_state_validation");
      return errors;
    },
    cityError() {
      const errors = [];
      if (!this.$v.userCountry.city.$dirty) {
        return errors;
      }
      !this.$v.userCountry.city.required &&
        errors.push("sign_up_mexico_city_validation");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      if (
        (!this.$v.user.password.valid && this.$v.user.password.required) ||
        (!this.$v.user.password.minLength && this.$v.user.password.required)
      ) {
        errors.push("password_hint");
        return errors;
      }
      !this.$v.user.password.required &&
        errors.push("sign_up_validation_password_required");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-register {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.req {
  color: red;
}
</style>
