var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',_vm._l((_vm.cart),function(order){return _c('div',{key:order.id,staticClass:"cart mb-5"},[_c('div',{staticClass:"header px-0",style:('background-color:' + order.color)},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pa-0 pl-5 pt-1",attrs:{"cols":"7"}},[_c('span',{staticClass:"white--text",staticStyle:{"font-weight":"700","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.locale == "en" ? order.cityPassTitleEn : order.cityPassTitleEs)+" ")])]),_c('v-col',{staticClass:"pa-0 col-5"},[_c('v-row',{staticClass:"mr-2",attrs:{"justify":"end","align":"center"}},[_c('div',{staticClass:"mt-1 mr-5"},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(new Date(order.started_at).toLocaleDateString("en-gb", {}))+" - "+_vm._s(order.end_date.toLocaleDateString("en-gb", {}))+" ")])]),_c('img',{staticClass:"mt-1",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/delete-icon-cart.svg"),"alt":"delete"},on:{"click":function($event){return _vm.$emit('deleteCitypassFromCart', order.uuid)}}})])],1)],1)],1),_c('v-row',{staticStyle:{"margin-top":"10px"},attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{staticStyle:{"margin-left":"20px"}},[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',[_vm._l((order.number),function(number){return _c('v-row',{key:number.id,staticClass:"mt-1 mb-auto",class:{ 'mb-2': !_vm.mazatlan },attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0 mb-2",style:(number.type == 'Kids' && _vm.mazatlan
                      ? 'margin-top: 10px;'
                      : ''),attrs:{"cols":"6"}},[_c('span',{staticClass:"textStyle"},[_vm._v(_vm._s(_vm._f("localize")(number.type == "Adult" ? "attractions_adult" : "cart_label_child")))]),_c('br'),(_vm.mazatlan)?_c('span',{staticClass:"textStyle",staticStyle:{"font-weight":"400 !important"}},[_vm._v(" MX$ "+_vm._s(new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, }).format(number.currentPrice)))]):_vm._e()]),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[(number.number > 0)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/minus.svg"),"alt":"minus"},on:{"click":function($event){number.number > 0
                        ? number.number--
                        : (number.number = 0),
                        _vm.changeSubtotal()}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/minus-disabled.svg"),"alt":"minus-disabled"},on:{"click":function($event){number.number > 1
                        ? number.number--
                        : number.type == 'Adult'
                        ? (number.number = 1)
                        : (number.number = 0),
                        _vm.changeSubtotal()}}}),_c('div',{staticStyle:{"width":"40px","display":"flex","justify-content":"center"}},[_c('span',{staticClass:"textStyle"},[_vm._v(_vm._s(number.number))])]),(
                      (number.type == 'Adult' &&
                        number.number < number.maxAdultAvailable) ||
                      (number.type == 'Kids' &&
                        number.number < number.maxChildAvailable)
                    )?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/plus.svg"),"alt":"plus"},on:{"click":function($event){number.number++, _vm.changeSubtotal()}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/plus-disabled.svg"),"alt":"plus"}})])],1)}),_c('div',{staticClass:"mt-1 mb-3"},[_c('span',{staticClass:"textStyle"},[_vm._v(_vm._s(_vm._f("localize")("infants_label")))])]),(_vm.mazatlan)?_c('v-row',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"6"}},[_c('label',{staticStyle:{"font-size":"16px","color":"#212121"},style:(order.subtotal.toString().length > 6
                        ? 'font-size: 14px'
                        : 'font-size:15px')},[_vm._v(" "+_vm._s(_vm._f("localize")("banking_charges_label")))])]),_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"end"},style:(order.subtotal.toString().length > 6
                      ? 'font-size: 14px'
                      : 'font-size:15px'),attrs:{"cols":"6"}},[_c('label',[_vm._v("MX$ "+_vm._s(new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, }).format(order.fee)))])])],1):_vm._e(),(order.additional && order.additional !== 0)?_c('v-row',{staticClass:"py-0",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:_vm.mazatlan ? 'col-6 px-0 py-0' : 'col-12 px-0 py-0'},[_c('label',{staticStyle:{"font-size":"16px","color":"#212121"},style:(order.subtotal.toString().length > 6
                        ? 'font-size: 14px'
                        : 'font-size:15px')},[_vm._v(" "+_vm._s(_vm._f("localize")(((_vm.mazatlan ? "mzt_" : "") + "bus_metro_label")))+_vm._s(_vm._f("localize")("public_transport_label2")))])]),(_vm.mazatlan)?_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"end"},style:(order.subtotal.toString().length > 6
                      ? 'font-size: 14px'
                      : 'font-size: 15px'),attrs:{"cols":"6"}},[_c('label',[_vm._v("MX$ "+_vm._s(new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, }).format(order.additional)))])]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"py-0",class:{ 'pt-4': !_vm.mazatlan },attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0 px-0",staticStyle:{"font-weight":"500","font-size":"16px","color":"#212121"},attrs:{"cols":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("localize")("cart_label_total_cost")))])]),_c('v-col',{staticClass:"py-0 px-0",staticStyle:{"font-weight":"500","color":"#212121","text-align":"end"},style:(order.subtotal.toString().length > 6
                      ? 'font-size: 14px'
                      : 'font-size:15px'),attrs:{"cols":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" MX$ "+_vm._s(new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, }).format(order.subtotal))+" ")])])],1)],2),_c('v-divider',{staticClass:"px-0 ml-10 mt-5 mb-5",staticStyle:{"opacity":"0.25","border":"1px solid #212121","margin-top":"10px"},attrs:{"vertical":"","heigth":"105px"}}),_c('v-col',{staticStyle:{"text-align":"left","margin-top":"20px"}},[_c('v-row',{staticClass:"mb-2 pl-5",attrs:{"no-gutters":"","align":"center","justify":"start"}},[_c('img',{attrs:{"src":require("@/assets/ticket_icon.svg"),"alt":"ticket"}}),_c('v-col',{staticClass:"py-0 px-0 ml-1",attrs:{"cols":"6"}},[_c('h3',{staticStyle:{"color":"#e62076","font-weight":"700","font-size":"20px","line-height":"20px"}},[_vm._v(" "+_vm._s(_vm._f("localize")("cart_label_free_attractions"))+" ")])])],1),_c('div',{staticClass:"pl-5",staticStyle:{"text-align":"center"}},_vm._l((order.attractions),function(attract,i){return _c('div',{key:i,staticStyle:{"text-align":"left"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"2"}},[_c('span',{staticClass:"ml-1",staticStyle:{"font-weight":"500","font-size":"16px","color":"black","text-align":"left"}},[_vm._v(_vm._s(i + 1)+".")])]),_c('v-col',{staticClass:"px-0",attrs:{"cols":"9"}},[_c('span',{staticStyle:{"font-weight":"500","font-size":"16px","color":"black","text-align":"left"}},[_vm._v(_vm._s(attract.title))])])],1)],1)}),0)],1)],1)],1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }