<template>
  <div class="d-flex align-center" style="min-height: 50vh">
    <v-progress-circular
      class="mx-auto"
      indeterminate
      color="#e62076"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Loader"
};
</script>

<style>
</style>