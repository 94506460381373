import en from '@/locales/en.json';
import es from '@/locales/es.json';
import requestService from '../requestService';
import store from '@/store';

export default {
    async getLanguage() {
        const response = await requestService.get(`languages`);
        let result = response?.data.result
        for (let i = 0; i < result.length; i++) {
            en[`${result[i].code}`] = result[i].en;
            es[`${result[i].code}`] = result[i].es;
        }
        es['Policy'] = `<p><span style="font-weight: 400;">En cumplimiento con los art&iacute;culos 15, 16 y dem&aacute;s relativos a la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de Particulares (en lo sucesivo LFPDPPP), el reglamento de la LFPDPPP, lineamientos y principio de protecci&oacute;n de datos personales, ponemos a su disposici&oacute;n el presente Aviso de Privacidad Integral, con el objeto delimitar los alcances y condiciones generales del tratamiento, as&iacute; como informar a los titulares, a fin de que est&eacute;n en posibilidad de tomar decisiones informadas sobre el uso de sus datos personales, y de mantener el control de sus datos personales por</span> <span style="font-weight: 400;">la </span><b>C&aacute;mara Nacional de Comercio, Servicio y Turismo de Guadalajara</b><span style="font-weight: 400;">, que en obvio de repeticiones se denominar&aacute; </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">, de conformidad con los siguientes puntos:</span></p>
        <p></p>
        <ol type="I">
        <li style="font-weight: 400;" aria-level="1"><b>IDENTIDAD Y DOMICILIO DEL RESPONSABLE.-</b><span style="font-weight: 400;"> La en obvio de repeticiones </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;"> es una instituci&oacute;n de inter&eacute;s p&uacute;blico, aut&oacute;noma con personalidad y patrimonio propio. Cuenta con domicilio para recibir todo tipo de documentos y notificaciones en la finca marcada con el n&uacute;mero 4095 de la Av. Vallarta en la colonia Camino Real, C.P. 45040, en el municipio de Zapopan, Jalisco;&nbsp;</span></li>

        <p></p>
        <li style="font-weight: 400;" aria-level="1"><b>OBTENCIÓN DE DATOS PERSONALES.- “LA CÁMARA” </b><span style="font-weight: 400;">podr&aacute; recabar sus datos personales de distintas formas:</span></li>
        <p></p>
        <ol type="a">
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando usted nos los proporciona directamente por escrito, v&iacute;a telef&oacute;nica y por correo electr&oacute;nico propiedad de la </span><b>C&aacute;mara</b><span style="font-weight: 400;">;&nbsp;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando visita nuestro sitio de Internet, redes web internas, utilice nuestros servicios en l&iacute;nea o redes sociales;&nbsp;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando obtenemos informaci&oacute;n a trav&eacute;s de otras fuentes que est&aacute;n permitidas por la ley; y&nbsp;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">C&aacute;maras de Video-vigilancia.&nbsp;</span></li>
</ol>
        <p></p>
  
        <li style="font-weight: 400;" aria-level="1"><b>LA INFORMACI&Oacute;N QUE SE RECABA.- &ldquo;LA C&Aacute;MARA&rdquo; </b><span style="font-weight: 400;">podr&aacute; recabar los siguientes:</span></li>
        <p></p>
        <ol type="A">
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Datos Personales Generales:&nbsp;</span></li>
        <p></p>
        <ol>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Nombre</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Estado Civil</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Registro Federal de Contribuyentes(RFC)</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Clave &uacute;nica de Registro de Poblaci&oacute;n (CURP)</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">lave de Elector</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Lugar de nacimiento</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Fecha de nacimiento</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Nacionalidad</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">&nbsp;Domicilio</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tel&eacute;fono particular</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tel&eacute;fono celular</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Correo electr&oacute;nico</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Firma aut&oacute;grafa</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Firma electr&oacute;nica</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Edad</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Fotograf&iacute;a</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Color de la piel</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Color del iris</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Color del cabello</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Se&ntilde;as particulares</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Estatura</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Peso</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cicatrices</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tipo de sangre</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Imagen del iris</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Huella dactilar</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Palma de la mano</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Puesto o cargo que desempe&ntilde;a</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Domicilio de trabajo</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Correo electr&oacute;nico institucional</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tel&eacute;fono institucional</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Referencias laborales</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Informaci&oacute;n generada durante los procesos de reclutamiento, selecci&oacute;n y contrataci&oacute;n</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Capacitaci&oacute;n laboral</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Trayectoria educativa</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">T&iacute;tulos Acad&eacute;micos.&nbsp;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">C&eacute;dula profesional</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Certificados</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Reconocimientos</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Entradas al pa&iacute;s</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Salidas del pa&iacute;s</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tiempo de permanencia en el pa&iacute;s</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Calidad migratoria</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Derechos de residencia</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Aseguramiento</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Repatriaci&oacute;n</span></li>
        <li style="font-weight: 400;" aria-level="1"><span y salas de reuniones.pan style="font-weight: 400;">Pasatiempos</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Aficiones</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Deportes que practica</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">N&uacute;mero de pasaporte;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Licencia de Conducir;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">N&uacute;mero de credencial para votar.</span></li>
            </ol>
            <br />
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Los Datos Sensibles que tratamos, son:</span></li>
            <br />
            <ol>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Estado de salud presente o futuro;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Creencias Religiosas;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Afiliaci&oacute;n Sindical.</span></li>
            </ol>
            <br />
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Los Datos Patrimoniales o Financieros:</span></li>
            <br />
            <ol>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">N&uacute;mero de tarjeta de cr&eacute;dito;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuentas Bancarias</span><span style="font-weight: 400;">;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Recibos de n&oacute;mina;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Constancia de retenci&oacute;n de impuestos;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cr&eacute;dito hipotecarios ante el INFONAVIT;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Declaraci&oacute;n Anual de Impuestos.</span></li>
            </ol>
            <br />
            <p><span style="font-weight: 400;">Por lo que solicitamos nos otorgue su anuencia para el tratamiento de datos sensibles, patrimoniales y financieros, a lo que le proporcionaremos el documento para el efecto.&nbsp;</span></p>
            <br />
            <p><span style="font-weight: 400;">Es responsabilidad del Titular de los Datos Personales el garantizar que los Datos Personales proporcionados a </span><b>LA C&Aacute;MARA</b><span style="font-weight: 400;"> sean veraces y completos, as&iacute; como comunicar inmediatamente, cualquier modificaci&oacute;n en los mismos, a efecto de que se pueda cumplir con la obligaci&oacute;n de mantener la informaci&oacute;n actualizada. Para fines de consulta y verificaci&oacute;n, as&iacute; como para cerciorarse de que los Datos Personales que usted libremente ha proporcionado, son correctos y actuales, </span><b>LA C&Aacute;MARA</b><span style="font-weight: 400;"> podr&aacute; recopilar informaci&oacute;n en base de datos p&uacute;blicos y de libre de acceso, a lo que podr&aacute;n ser: referencias personales, referencias laborales y de otras fuentes tales como directorios telef&oacute;nicos o laborales, bolsas de trabajo, bases de datos consideradas como p&uacute;blicas, entre otras.</span></p>
            <br />
            <p><span style="font-weight: 400;">La temporalidad del manejo de los Datos Personales del Titular en todos los casos ser&aacute; s&oacute;lo por el tiempo razonable y para cumplir con las obligaciones que se deriven con motivo del v&iacute;nculo que tiene el Titular de los Datos con la responsable </span><b>LA C&Aacute;MARA</b><span style="font-weight: 400;">.</span></p>
            </li>
            </ol>
            <li style="font-weight: 400;" aria-level="1"><b>LA FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES.- </b><span style="font-weight: 400;">La finalidad del tratamiento de los datos personales, incluyendo los Datos Personales Sensibles, ser&aacute;:</span></li>
            <br />
            <ol type="a">
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Usarla para ponerse en contacto por medio de tel&eacute;fono, correo electr&oacute;nico, publicaciones impresas o por cualquier otro medio con su titular y/o Representante Legal y/o sus colaboradores con el objeto se hacerle llegar informaci&oacute;n comercial, econ&oacute;mica, cursos de capacitaci&oacute;n del CEFOR o cualquier informaci&oacute;n oportuna para el buen desarrollo de sus actividades como comerciante y/o prestador de servicios ofertados en general de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;"> y/o sus aliados estrat&eacute;gicos.&nbsp;</span></li>
           </br> <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Adem&aacute;s, para invitarlo a eventos institucionales, asamblea de socios, cesiones de consejo, eventos comerciales, cursos, ferias o exposiciones, misiones comerciales, juntas, reuniones de trabajos de secciones especializadas o cualquier otra &aacute;rea de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">, eventos tur&iacute;sticos, culturales y/o art&iacute;sticos, presentaci&oacute;n de servicios propios o de los socios comerciales de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">.&nbsp;</span></li>
           </br><li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Con el prop&oacute;sito conocer indicadores econ&oacute;micos, financieros, sociales, demogr&aacute;ficos, productivos,&nbsp; entre otros, mediantes rastreo, encuestas o sondeos de opini&oacute;n del Centro de An&aacute;lisis Estrat&eacute;gico Empresarial (CAEE) de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">.</span></li>
           </br><li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">En el caso de titulares de datos personales que nos proporcionan sus datos por medio de su curr&iacute;culum, solicitud de empleo o la captura de datos en el portal de internet del Departamento de Bolsa de Trabajo propiedad de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">, es decir,</span> <a href="http://www.bolsatrabajocamaradecomerciogdl.mx"><span style="font-weight: 400;">www.bolsatrabajocamaradecomerciogdl.mx</span></a><span style="font-weight: 400;">, la &uacute;nica finalidad de su tratamiento de datos personales ser&aacute; promoverlos con empresarios y sus colaboradores para que conozcan su perfil personal, socioecon&oacute;mico y laboral. Con la misma finalidad los datos personales de las personas f&iacute;sicas con actividades empresariales o personas morales y sus representantes podr&aacute;n ser divulgadas a las personas f&iacute;sicas que busquen empleo para dar a conocer los requisitos para postular a las vacantes o puestos laborales que requieran. Los datos personales sensibles que en caso llegue a recopilar </span><b>&ldquo;LA C&Aacute;MARA&rdquo; </b><span style="font-weight: 400;">tienen la &uacute;nica finalidad de identificar con mayor precisi&oacute;n la personalidad del solicitante de empleo para cubrir las expectativas de los empleadores.&nbsp;</span></li>
           </br> <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">De conformidad con la fracci&oacute;n IV del art&iacute;culo 07 de la Ley de C&aacute;maras Empresariales y sus Confederaciones donde faculta </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b> <span style="font-weight: 400;">para operar el Sistema de Informaci&oacute;n Empresarial Mexicano (SIEM) recabara los datos personales para ser publicados en la p&aacute;gina de internet con direcci&oacute;n buci&oacute;n econ&oacute;mica en los sectores vinculados al sector tur&iacute;stico, a trav&eacute;s de un pase digital con acceso a sus instalaciones otorgando precios preferenciales en la misma&nbsp; y/o a sus eventos&nbsp; mensuales de impacto, nacionales e internacionales.&nbsp;</span></li>
           </br> <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">En la plataforma digital </span><b>C&aacute;mara Connect</b><span style="font-weight: 400;"> con el objeto vinculaci&oacute;n en forma digital para los socios de la C&aacute;mara, para potenciar su avance empresarial y estar informados sobre los temas m&aacute;s relevantes para el sector empresarial, generar contactos de negocios, mantenerte informado con los t&oacute;picos, obtener informaci&oacute;n sobre eventos importantes, asistir a cursos de capacitaci&oacute;n y diplomados en colaboraci&oacute;n con las universidades m&aacute;s importantes hechos a tu medida para fortalecer el desarrollo de tu empresa, participar en diferentes comunidades basadas en temas de inter&eacute;s y/o giros comerciales, acercarte m&aacute;s f&aacute;cilmente a los diferentes servicios con los que cuenta la C&aacute;mara de Comercio de Guadalajara, como:</span></li>
            <span style="font-weight: 400;">Asesor&iacute;a Empresarial, Legal, Fiscal, Comercio Exterior, Bolsa de Trabajo, Concierge Tecnol&oacute;gico y espacios creativos pensados con la finalidad de impulsar la creatividad de tu empresa como nuestro Distrito C2G, donde encontrar&aacute;s el Go- Action y espacios de Networking y salas de reuniones.</span></br>
            </br>  <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">La</span><b> C&aacute;mara </b><span style="font-weight: 400;">es titular del pase tur&iacute;stico denominado &ldquo;Guadalajara Pass&rdquo; o &ldquo;GDL Pass&rdquo;, que ofrece la promoci&oacute;n tur&iacute;stica y cultural de las atracciones que se ofrecen en el &aacute;rea metropolitana de Guadalajara con promociones y descuentos para los que lo adquieran .&nbsp;</span></li>
            </br> <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Finalidades Secundarias: Evaluaci&oacute;n de calidad en los servicios o productos que adquiera, circulares informativas de promociones y para invitarlo a eventos.</span></li>
            </ol>
            <br />
            <p><span style="font-weight: 400;">En caso de que no desee que sus datos personales sean tratados para estos fines, usted puede presentar desde este momento mediante un escrito de conformidad con el cap&iacute;tulo VI de este aviso de privacidad. La negativa para el uso de sus datos personales para estas finalidades no podr&aacute; ser un motivo para que le neguemos los servicios que solicita o contrata con nosotros.</span></p>
            <li style="font-weight: 400;" aria-level="1"><b>LIMITACI&Oacute;N DE USO O DIVULGACI&Oacute;N DE LOS DATOS PERSONALES.- &ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;"> cuenta con las medidas de seguridad, administrativas, t&eacute;cnicas y f&iacute;sicas necesarias e implementadas conforme a sus pol&iacute;ticas y procedimientos de seguridad, para asegurar sus Datos Personales contra un uso indebido o il&iacute;cito, un acceso no autorizado, o contra la p&eacute;rdida, alteraci&oacute;n, robo o modificaci&oacute;n de sus Datos Personales, quedando prohibido su divulgaci&oacute;n il&iacute;cita y limitando su tratamiento conforme a lo previsto en el presente Aviso de Privacidad y en la legislaci&oacute;n aplicable.</span></li>
           <br /> <li style="font-weight: 400;" aria-level="1"><b>PROCEDIMIENTO DE ACCESO, RECTIFICACI&Oacute;N, CANCELACI&Oacute;N Y OPOSICI&Oacute;N (ARCO).- &ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;"> con la finalidad de resguardar de la mejor manera posible su informaci&oacute;n se compromete a tratar sus datos personales bajo estrictas medidas de seguridad para garantizar su confidencialidad. Se han establecido est&aacute;ndares de seguridad administrativa, legales y tecnol&oacute;gicos para evitar que terceros no autorizados se apoderen de sus datos personales.</span></li>
           <br /> <p><span style="font-weight: 400;">Usted puede enviar un correo electr&oacute;nico con su petici&oacute;n de limitaci&oacute;n de uso o divulgaci&oacute;n, dirigido al correo electr&oacute;nico: </span><a href="mailto:legal@camaradecomerciogdl.mx"><span style="font-weight: 400;">legal@camaradecomerciogdl.mx</span></a><span style="font-weight: 400;">, dirigido a Direcci&oacute;n Jur&iacute;dica de </span><b>&ldquo;LA C&Aacute;MARA&rdquo; </b><span style="font-weight: 400;">quien es el Encargado de la protecci&oacute;n de sus datos personales.</span></p>
            <ol type="a">
            <li style="font-weight: 400;" aria-level="1"><a href="http://www.siem.gob.mx"><span style="font-weight: 400;">www.siem.gob.mx</span></a><span style="font-weight: 400;">, con el fin de suministrar la informaci&oacute;n oportuna y confiable sobre las caracter&iacute;sticas y ubicaci&oacute;n de los establecimientos y actividades productivas de comercio, servicios y turismo del &aacute;mbito nacional, sirviendo est&aacute; como referencia para la identificaci&oacute;n de oportunidades comerciales y de negocios para cualquier individuo nacional y extranjero.</span></li>
           <br/> <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Podremos utilizar sus datos personales para vincular a personas f&iacute;sicas o morales que requieran comprar, vender productos o brindar servicios dentro del portal denominado &ldquo;Vamos Comerciando&rdquo;, en la siguiente p&aacute;gina web : </span><a href="https://www.camaraconnect.com/spaces/8210974/feed"><span style="font-weight: 400;">https://www.camaraconnect.com/spaces/8210974/feed</span></a><span style="font-weight: 400;"> que es propiedad y operado por </span><b>&ldquo;LA C&Aacute;MARA&rdquo; </b><span style="font-weight: 400;">con la finalidad de favorecer la econom&iacute;a en los sector comercio, servicios y turismo.</span></li>
           <br/> <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Finalidad dirigida a personas que pretendan laborar en la instituci&oacute;n y los empleados de la misma: para el proceso de evaluaci&oacute;n, reclutamiento y selecci&oacute;n de personal; tramitar el alta ante el Instituto Mexicano del Seguro Social; Para la administraci&oacute;n de su expediente laboral; Integraci&oacute;n de los documentos necesarios para el desempe&ntilde;o de su servicios; En caso de tener cr&eacute;dito hipotecario y Pago de prestaciones laborales.</span></li>
           <br/>  <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Organizar y generar actividades para la creaci&oacute;n y publicaci&oacute;n de contenido; Intercambio de informaci&oacute;n para el llenado y promoci&oacute;n en el pase digital tur&iacute;stico y cultural; establecer la participaci&oacute;n conjunta en redes de cooperaci&oacute;n; establecer bases y actividades espec&iacute;ficas para la participaci&oacute;n mutua; La futura colaboraci&oacute;n para la promoci&oacute;n, difusi&oacute;n, promoci&oacute;n y designaci&oacute;n de tareas espec&iacute;ficas. Todo lo mencionado con la finalidad de consolidar a Guadalajara como un destino tur&iacute;stico, generando una atracci&oacute;n y distri</span></li>
            </ol>
            <br/>  <p><span style="font-weight: 400;">Durante el tratamiento de los datos personales que usted nos proporcione, estableceremos las medidas necesarias para cumplir con los principios de licitud, calidad, consentimiento, informaci&oacute;n, finalidad, lealtad, proporcionalidad y responsabilidad que marca la LFPDPPP y el Reglamento los datos personales que usted nos proporcione.</span></p>
      
            <p><span style="font-weight: 400;">Cabe mencionar que el titular de los datos personales puede iniciar un procedimiento de Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n (Por sus siglas ARCO) para limitar el uso o divulgar sus datos personales de conformidad con el cap&iacute;tulo VI del presente aviso de privacidad.</span></p>
         
            <p><span style="font-weight: 400;">El procedimiento para ejercer los derechos de ARCO al tratamiento de sus datos personales consiste en enviar un correo electr&oacute;nico con su petici&oacute;n a la direcci&oacute;n de correo electr&oacute;nico </span><a href="mailto:concenter@camaradecomerciogdl.mx"><span style="font-weight: 400;">legal@camaradecomerciogdl.mx</span></a><span style="font-weight: 400;"> dirigido a Susana Gonzalez Jimenez en su calidad de responsable de atender sus solicitudes de ARCO.</span></p>
        
            <p><span style="font-weight: 400;">Por lo que </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;"> se compromete a dar contestaci&oacute;n a su petici&oacute;n dentro de los 10 (Diez) d&iacute;as h&aacute;biles posteriores a la recepci&oacute;n de su correo electr&oacute;nico. Su solicitud deber&aacute; contener al menos:</span></p>
         
            <ol type="a">
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Su nombre y domicilio;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Medio para recibir comunicaciones;&nbsp;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Su identificaci&oacute;n Oficial o documentos que acrediten la personalidad de la persona moral y el poder del representante legal;&nbsp;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">En su caso el n&uacute;mero de socio con el que est&aacute; afiliado a </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">&nbsp;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">La explicaci&oacute;n clara y precisa de los Datos Personales a los cuales quieres tener acceso, rectificar, cancelar u oponerse; y&nbsp;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cualquier otro elemento o documento que facilite la localizaci&oacute;n de sus Datos Personales.</span></li>
            </ol>
            <br />
            <p><span style="font-weight: 400;">En caso de faltar alguna informaci&oacute;n en su solicitud se le solicitar&aacute; subsane la omisi&oacute;n a la brevedad. En caso de que se aprecie que la documentaci&oacute;n contenga tachaduras, se observe alguna alteraci&oacute;n, se tenga duda razonable de ser ap&oacute;crifa o no ser el leg&iacute;timo representante del titular de datos personales. Se le solicitar&aacute; que presente la documentaci&oacute;n en original o copias certificadas en la oficina del responsable.&nbsp;</span></p>
            </li>
            <li style="font-weight: 400;" aria-level="1"><b>TRANSFERENCIA DE DATOS.-</b><span style="font-weight: 400;"> Las transferencias de datos que realice </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;"> de sus datos personales ser&aacute; exclusivamente a autoridades, proveedores o colaboradores comerciales de servicios seleccionados que contratemos, con quienes tenemos celebrados convenio de confidencialidad y de protecci&oacute;n de datos personales, en el entendido que s&oacute;lo se les permitir&aacute; el acceso a sus datos personales para cumplir con la prestaci&oacute;n de los servicios contratados o adquirir productos que nos solicita con el objeto de cumplir con finalidades establecidas en este Aviso de Privacidad.</span></li>
            <br />
            <p><span style="font-weight: 400;">En ning&uacute;n otro caso comercializaremos, venderemos o rentaremos informaci&oacute;n personal sobre usted a un tercero sin su consentimiento previo. Cualquier transferencia de datos personales que </span><b>&ldquo;LA C&Aacute;MARA&rdquo; </b><span style="font-weight: 400;">realice, ser&aacute; &uacute;nicamente para los fines permitidos por las leyes. De conformidad con la legislaci&oacute;n aplicable, se considera&nbsp; que el titular de los datos personales consiente t&aacute;citamente el tratamiento de sus datos, si no manifieste su oposici&oacute;n. Los datos personales que usted proporcione a </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">, podr&aacute;n compilarse y fijarse en una base de datos propiedad exclusiva de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">.&nbsp;</span></p>
       
            <p><span style="font-weight: 400;">Las ligas a sitios externos de este portal, no son responsabilidad de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">, por lo que no asumimos responsabilidad alguna con respecto al contenido y pol&iacute;ticas de privacidad en dichos sitios.</span></p>
       
            <p><span style="font-weight: 400;">Se podr&aacute;n hacer transferencias sin su consentimiento de acuerdo a lo previsto en el art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de datos Personales en Posesi&oacute;n de los Particulares, as&iacute; como a realizar esta transferencia en los t&eacute;rminos que fija&nbsp; esa ley, a lo que establecemos los supuestos:&nbsp;</span></p>
         
            <ol type="a">
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia est&eacute; prevista en una Ley;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia sea necesaria para la prevenci&oacute;n o el diagn&oacute;stico m&eacute;dico, la prestaci&oacute;n de asistencia sanitaria, tratamiento m&eacute;dico o la gesti&oacute;n de servicios sanitarios;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo Grupo del responsable que opere bajo los mismos procesos y pol&iacute;ticas internas;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en inter&eacute;s del titular, por el responsable y un tercero;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un inter&eacute;s p&uacute;blico, o para la procuraci&oacute;n o administraci&oacute;n de justicia;</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; y</span></li>
            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relaci&oacute;n jur&iacute;dica entre el responsable y el titular.</span></li>
            </li>
            </ol>
            <br />
            <li style="font-weight: 400;" aria-level="1"><b>PROCEDIMIENTO Y MEDIO PARA REALIZAR CAMBIOS AL AVISO DE PRIVACIDAD.- </b><span style="font-weight: 400;">El procedimiento y medio por el cual </span><b>&ldquo;LA C&Aacute;MARA&rdquo; </b><span style="font-weight: 400;">comunicar&aacute; a los titulares de cambios al aviso de privacidad consistir&aacute; en la publicaci&oacute;n electr&oacute;nica en la p&aacute;gina de internet de </span><b>&ldquo;LA C&Aacute;MARA&rdquo;</b><span style="font-weight: 400;">: </span><a href="http://www.camaradecomerciogdl.mx"><span style="font-weight: 400;">www.camaradecomerciogdl.mx</span></a></li>
    </ol>
    <span style="margin-bottom: 1px">&nbsp;</span>
 <v-row no-gutters align="end">
 <p><b>Fecha de modificación: 06 de junio del 2014
 </b></p>
<p><b>Fecha de última actualización: 27 de abril del 2023
</b></p>
 </v-row>

`
        en['Policy'] = `<p>In compliance with articles 15, 16 and others related to the Federal Law for the Protection of Personal Data in
        Possession of Private Parties (hereinafter LFPDPPP), the regulation of the LFPDPPP, guidelines and principle of
        protection of personal data, we make available this Privacy Notice, in order to delimit the scope and general
        conditions of treatment, as well as to inform the owners, so that they are able to make informed decisions about the
        use of their personal data, and to maintain control of their personal data by the National <strong>Chamber of
            Commerce, Service and Tourism of Guadalajara</strong>, which in obvious repetition will be called <strong>'THE
            CHAMBER</strong>', in accordance with the following points:</p>
    <ol type='I'>
        <li><strong> </strong><strong>IDENTITY AND ADDRESS OF THE RESPONSIBLE - </strong>The <strong>'THE CHAMBER'</strong>
            is an institution of public interest, autonomous with its own personality and patrimony. It is domiciled to
            receive all types of documents and notifications in the property marked with the number 4095 of Vallarta Avenue
            in the Camino Real colony, C.P. 45040, in the municipality of Zapopan, Jalisco<strong>;</strong></li>
    <span style="margin-bottom: 1px">&nbsp;</span>
        <li><strong> </strong><strong>OBTAINING PERSONAL DATA - 'THE CHAMBER'</strong> may collect your personal data in
            different ways:</li>
        <ol>
            <li>When you provide them to us directly in writing, by telephone and by email owned by the Chamber;</li>
            <li>When you visit our website, internal web networks, use our online services or social networks;</li>
            <li>When we obtain information through other sources that are permitted by law; and</li>
            <li>Video surveillance cameras.</li>
        </ol>
        <span style="margin-bottom: 1px">&nbsp;</span>
        <li><strong> </strong><strong>INFORMATION COLLECTED - 'THE CHAMBER'</strong> may collect the following:</li>
    
        <ol type='A'>
            <li>General Personal Data:</li>
            <ol>
                <li>Name</li>
                <li>Marital Status</li>
                <li>Federal Taxpayers Register (RFC)</li>
                <li>Unique Population Registration Number (CURP)</li>
                <li>Voter's identification number (ID Code number)</li>
                <li>Place of birth</li>
                <li>Date of birth</li>
                <li>Nationality</li>
                <li>Address</li>
                <li>Home telephone number</li>
                <li>Mobile pone</li>
                <li>E-mail address</li>
                <li>Autograph signatura</li>
                <li>Electronic signatura</li>
                <li>Age</li>
                <li>Photograph</li>
                <li>Skin colour</li>
                <li>Colour of iris</li>
                <li>Colour of hair</li>
                <li>Distinguishing marks</li>
                <li>Height</li>
                <li>Weight</li>
                <li>Scars</li>
                <li>Blood type</li>
                <li>Iris image</li>
                <li>Fingerprint</li>
                <li>Palm of the hand</li>
                <li>Position or position held</li>
                <li>Work address</li>
                <li>Institutional e-mail address</li>
                <li>Business telephone</li>
                <li>Work references</li>
                <li>Information generated during recruitment, selection and hiring processes</li>
                <li>Job training</li>
                <li>Educational backround</li>
                <li>Academic Degrees</li>
                <li>Professional credentials</li>
                <li>Certificates</li>
                <li>Acknowledgements</li>
                <li>Entry into the country</li>
                <li>Departures from the country</li>
                <li>Length of stay in the country</li>
                <li>Migratory status</li>
                <li>Residence rights</li>
                <li>Insurance</li>
                <li>Repatriation</li>
                <li>Hobbies</li>
                <li>Interests</li>
                <li>Sports you play</li>
                <li>Passport number</li>
                <li>Driving licence;</li>
                <li>Voting card number.</li>
            </ol>
            <li>The Sensitive Data that we process, are:</li>
    
            <ol>
                <li>Present or future health status;</li>
                <li>Religious beliefs;</li>
                <li>Union membership.</li>
            </ol>
    
            <li>Personal or Financial Data:</li>
    
            <ol>
                <li>Credit card number;</li>
                <li>Bank Accounts;</li>
                <li>Payroll receipts;</li>
                <li>Proof of tax withholding;</li>
                <li>Mortgage credit from INFONAVIT;</li>
                <li>Annual Tax Return.</li>
            </ol>
        </ol>
        <p>Therefore, we request your consent for the treatment of sensitive, patrimonial and financial data, to which
            we
            will
            provide you with the document for this purpose.</p>
        <p>It is the responsibility of the Holder of the Personal Data to ensure that the Personal Data provided to
            <strong>THE
                CHAMBER</strong> are accurate and complete, as well as to immediately communicate any changes in them,
            in
            order
            to comply with the obligation to keep the information updated. For consultation and verification purposes,
            as
            well
            as to ensure that the Personal Data that you have freely provided are correct and current, THE CHAMBER may
            collect
            information in public and freely accessible databases, which may include: personal references, employment
            references
            and other sources such as telephone or employment directories, job boards, databases considered as public,
            among
            others.
        </p>
        <p>The temporality of the handling of the Data Subject's Personal Data in all cases will only be for a
            reasonable
            period
            of time and to comply with the obligations arising from the relationship between the Data Subject and
            <strong>THE
                CHAMBER.</strong>
        </p>
        <li><strong> </strong><strong>PURPOSE OF THE PROCESSING OF PERSONAL DATA</strong> - The purpose of the
            processing of
            personal data, including Sensitive Personal Data, shall be:</li>
        <ol type='a'>
            <li><strong> </strong>Use it to contact by telephone, e-mail, printed publications or by any other means
                with
                its
                owner and/or Legal Representative and/or its collaborators in order to send commercial and economic
                information,
                CEFOR training courses or any other timely information for the proper development of its activities as a
                merchant and/or service provider offered in general by <strong>'THE CHAMBER'</strong> and/or its
                strategic
                allies.</li>
            <li><strong> </strong>In addition, to invite you to institutional events, assembly of partners, council
                assignments,
                commercial events, courses, fairs or exhibitions, commercial missions, meetings, work meetings of
                specialised
                sections or any other area of <strong>'THE CHAMBER',</strong> tourist, cultural and/or artistic events,
                presentation of our own services or those of the commercial partners of <strong>'THE CHAMBER'</strong>.
            </li>
            <li><strong> </strong>With the purpose of knowing economic, financial, social, demographic, productive
                indicators,
                among others, through tracking, surveys or opinion polls of the Centre for Strategic Business Analysis
                (CAEE) of
                <strong>'THE CHAMBER'.</strong>
            </li>
            <li><strong> </strong>In the case of holders of personal data who provide us with their data by means of
                their
                CV,
                job application or data capture on the internet portal of the Job Opportunities Department owned by
                <strong>'THE
                    CHAMBER'</strong>, i.e. www.bolsatrabajocamaradecomerciogdl.mx, the sole purpose of processing their
                personal data will be to promote them with employers and their collaborators so that they may be aware
                of
                their
                personal, socio-economic and employment profile. For the same purpose, the personal data of individuals
                with
                business activities or legal entities and their representatives may be disclosed to individuals seeking
                employment in order to inform them of the requirements for applying for job vacancies or positions they
                require.
                The sensitive personal data that may be collected by <strong>'THE CHAMBER'</strong> has the sole purpose
                of
                identifying with greater precision the personality of the job applicant in order to meet the
                expectations of
                employers.
            </li>
            <li><strong> </strong>In accordance with section IV of article 07 of the Law of Business Chambers and their
                Confederations where <strong>'THE CHAMBER'</strong> is empowered to operate the Mexican Business
                Information
                System (SIEM) will collect personal data to be published on the website with address www.siem.gob.mx, in
                order
                to provide timely and reliable information on the characteristics and location of establishments and
                productive
                activities of trade, services and tourism at the national level, serving as a reference for the
                identification
                of commercial and business opportunities for any national and foreign individual.</li>
    
            <li><strong> </strong>We may use your personal data to link individuals or companies that require to buy,
                sell
                products or provide services within the portal called 'Vamos Comerciando', on the following website:
                https://www.camaraconnect.com/spaces/8210974/feed which is owned and operated by <strong>'THE CHAMBER'
                </strong>in order to promote the economy in the trade, services and tourism sector.</li>
    
            <li><strong> </strong>Purpose aimed at people who intend to work in the institution and its employees: for
                the
                process of evaluation, recruitment and selection of personnel; to process the registration with the
                Mexican
                Institute of Social Security; for the administration of their labour file; integration of the necessary
                documents for the performance of their services; in case of having a mortgage loan and payment of
                employment
                benefits.</li>
    
            <li><strong> </strong>Organise and generate activities for the creation and publication of content; Exchange
                of
                information for the filling and promotion in the tourist and cultural digital pass; Establish joint
                participation in cooperation networks; Establish bases and specific activities for mutual participation;
                Future
                collaboration for the promotion, dissemination, promotion and designation of specific tasks. All the
                aforementioned with the aim of consolidating Guadalajara as a tourist destination, generating an
                attraction
                and
                economic distribution in the sectors linked to the tourism sector, through a digital pass with access to
                its
                facilities granting preferential prices in the same and/or to its monthly events of impact, national and
                international.</li>
    
            <li><strong> </strong>In the digital platform C&aacute;mara Connect with the purpose of linking in digital
                form
                for
                members of the Chamber, to enhance their business advancement and be informed about the most relevant
                issues
                for
                the business sector, generate business contacts, keep you informed with the topics, get information on
                important
                events, attend training courses and diploma courses in collaboration with the most important
                universities,
                tailor-made to strengthen the development of your company, participate in different communities based on
                topics
                of interest and/or business areas, get closer to the different services offered by the Guadalajara
                Chamber
                of
                Commerce, such as:
    
                Business, Legal, Tax, Foreign Trade, Job Opportunities, Technological Concierge and creative spaces
                designed
                with
                the
                purpose of boosting the creativity of your company such as our C2G District, where you will find the
                Go-Action
                and
                Networking spaces and meeting rooms.
            </li>
            <li><strong> </strong>The Chamber is the holder of the tourist pass called 'Guadalajara Pass' or 'GDL Pass',
                which
                offers tourist and cultural promotion of the attractions offered in the metropolitan area of Guadalajara
                with
                promotions and discounts for those who acquire it.</li>
            <li><strong> </strong>Secondary Purposes: Evaluation of quality in the services or products that you
                acquire,
                informative circulars of promotions and to invite you to events.</li>
        </ol>
        <p>In case you do not want your personal data to be processed for these purposes, you can submit from this
            moment by
            writing to us in accordance with chapter VI of this privacy notice. The refusal to use your personal data
            for
            these
            purposes shall not be a reason for us to deny you the services you request or contract with us.</p>
    
        <li><strong> </strong><strong>LIMITATION OF USE OR DISCLOSURE OF PERSONAL DATA - 'THE CHAMBER'</strong> has the
            necessary administrative, technical and physical security measures implemented in accordance with its
            security
            policies and procedures to secure your Personal Data against improper or unlawful use, unauthorised access,
            or
            against the loss, alteration, theft or modification of your Personal Data, prohibiting its unlawful
            disclosure
            and limiting its processing in accordance with the provisions of this Privacy Notice and the applicable
            legislation.</li>
            <span style="margin-bottom: 1px">&nbsp;</span>
        <li><strong> </strong><strong>PROCEDURE OF ACCESS, RECTIFICATION, CANCELLATION AND OPPOSITION (ARCO) - 'THE
                CHAMBER'</strong>, in order to safeguard your information in the best possible way, undertakes to treat
            your
            personal data under strict security measures to ensure its confidentiality. Administrative, legal and
            technological security standards have been established to prevent unauthorised third parties from getting
            hold
            of your personal data.</li>
    
        <p>You may send an e-mail with your request for limitation of use or disclosure, addressed to the following
            e-mail
            address: <span><a href='mailto:legal@camaradecomerciogdl.mx'>legal@camaradecomerciogdl.mx</a></span>,
            addressed
            to
            the Legal Department of <strong>'THE CHAMBER'</strong>, which is responsible for the protection of your
            personal
            data.</p>
        <p>During the processing of the personal data that you provide us with, we will establish the necessary measures
            to
            comply with the principles of legality, quality, consent, information, purpose, loyalty, proportionality and
            responsibility that the LFPDPPP and the Regulations establish for the personal data that you provide us
            with.
        </p>
        <p>It is worth mentioning that the owner of the personal data may initiate a procedure of Access, Rectification,
            Cancellation or Opposition (ARCO) to limit the use or disclosure of their personal data in accordance with
            chapter
            VI of this privacy notice.</p>
        <p>The procedure to exercise your ARCO rights to the processing of your personal data consists of sending an
            e-mail
            with
            your request to the email address legal@camaradecomerciogdl.mx addressed to Susana Gonzalez Jimenez in her
            capacity
            as the person responsible for dealing with your ARCO requests.</p>
        <p>Therefore, <strong>'THE CHAMBER'</strong> undertakes to respond to your request within 10 (Ten) working days
            after
            receiving your email. Your request must contain at least</p>
        <ol type='a'>
            <li>Your name and address;</li>
            <li>Means of receiving communications;</li>
            <li>Your official identification or documents proving the personality of the legal entity and the power of
                attorney
                of the legal representative;</li>
            <li><strong> </strong>If applicable, the membership number with which it is affiliated to <strong>'THE
                    CHAMBER'.
                </strong></li>
            <li>A clear and precise explanation of the Personal Data to which you wish to have access, rectify, cancel
                or
                oppose; and</li>
            <li>Any other element or document that facilitates the location of your Personal Data.</li>
        </ol>
        <p>If any information is missing in your request, you will be asked to correct the omission as soon as possible.
            In
            the
            event that the documentation is found to contain erasures, any alteration is observed, there is reasonable
            doubt
            that it is apocryphal or that you are not the legitimate representative of the holder of the Personal Data.
            You
            will
            be asked to present the documentation in original or certified copies at the office of the data controller.
        </p>
    
        <li><strong> </strong><strong>TRANSFER OF DATA.- </strong>Data transfers made by<strong> 'THE CHAMBER'
            </strong>of
            your personal data will be exclusively to authorities, suppliers or commercial collaborators of selected
            services that we contract, with whom we have entered into confidentiality and personal data protection
            agreements, in the understanding that they will only be allowed access to your personal data to comply with
            the
            provision of contracted services or acquire products that you request from us in order to comply with the
            purposes set forth in this Privacy Notice.</li>
        <p>In no other case will we market, sell or rent personal information about you to a third party without your
            prior
            consent. Any transfer of personal data by<strong> 'THE CHAMBER' </strong>will be solely for the purposes
            permitted
            by law. In accordance with applicable law, the owner of the personal data is deemed to tacitly consent to
            the
            processing of his or her data if he or she does not express his or her opposition. The personal data you
            provide
            to<strong> 'THE CHAMBER' </strong>may be compiled and stored in a database owned exclusively by<strong> 'THE
                CHAMBER'. </strong></p>
    
        <p>Links to external sites on this site are not the responsibility of 'THE CHAMBER' and we assume no
            responsibility
            for
            the content and privacy policies on such sites.</p>
        <p>Transfers may be made without your consent in accordance with the provisions of Article 37 of the Federal Law
            on
            Protection of Personal Data Held by Private Parties, as well as to make this transfer in the terms set forth
            in
            that
            law, to which we establish the assumptions:</p>
    
        <ol type='a'>
            <li>When the transfer is provided for in a Law;</li>
            <li>When the transfer is necessary for prevention or medical diagnosis, the provision of health care,
                medical
                treatment or the management of health services;</li>
            <li>Where the transfer is made to holding companies, subsidiaries or affiliates under common control of the
                controller, or to a parent company or any company in the same Group as the controller which operates
                under
                the
                same internal policies and processes;</li>
            <li>Where the transfer is necessary pursuant to a contract entered into or to be entered into in the
                interest of
                the
                data controller by the data controller and a third party;</li>
            <li>When the transfer is necessary or legally required for the safeguarding of a public interest, or for the
                administration or administration of justice;</li>
            <li>When the transfer is necessary for the recognition, exercise or defence of a right in legal proceedings;
                and
            </li>
            <li>When the transfer is necessary for the maintenance or fulfilment of a legal relationship between the
                data
                controller and the owner.</li>
        </ol>
        <span style="margin-bottom: 1px">&nbsp;</span>
        <li style='font-weight: 400;'><b>PROCEDURE AND MEANS FOR MAKING CHANGES TO THE PRIVACY NOTICE.-&nbsp; </b><span style='font-weight: 400;'>The procedure and means by which</span><b> 'THE CHAMBER' </b><span style='font-weight: 400;'>will communicate to the owners of changes to the privacy notice will consist of the electronic publication on the website of </span><b>'THE CHAMBER': </b><a href='http://www.camaradecomerciogdl.mx'><span style='font-weight: 400;'>www.camaradecomerciogdl.mx</span></a><span style='font-weight: 400;'>&nbsp;&nbsp;&nbsp;</span></li>
    </ol>
    <span style="margin-bottom: 1px">&nbsp;</span>
 <v-row no-gutters align="end">
 <p><b>Date of modification: Jun 6th, 2014</b></p>
<p><b>Date of the last modification: April 27th, 2023</b></p>
 </v-row>

`;
        return response?.data
    },
    async updateLanguage(code, form) {
        const response = await requestService.put(`languages / ` + code, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token} `,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY} `
            }
        });

        en[code] = form.en;
        es[code] = form.es;

        return response?.data
    },
    async addLanguage(form) {
        const response = await requestService.put(`languages / `, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token} `,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY} `
            }
        });
        let result = response?.data.result
        for (let i = 0; i < result.length; i++) {
            en[`${result[i].code} `] = result[i].en;
            es[`${result[i].code} `] = result[i].es;
        }
        console.log(en)
        return response?.data
    },
}