import requestService from '../requestService';
import store from '@/store';

export default {
	async getCitypasses() {
		let headers = { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}` }
		if (store.getters.loggedUser) {
			headers = { 'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`, Authorization: `Bearer ${store.getters.loggedUser.token}` }
		}
		const response = await requestService.get(`citypasses`, {
			headers: headers
		})
		return response?.data
	},
	async addCityPass(form) {
		const response = await requestService.post(`citypasses`, form, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async deleteCityPass(uuid) {
		const response = await requestService.delete(`citypasses/` + uuid, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getCitypass(uuid) {
		const response = await requestService.get(`citypasses/${uuid}`)
		return response?.data
	},
	async updateCitypass(uuid, form) {
		const response = await requestService.put(`citypasses/${uuid}`, form, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async deleteAttractionFromCityPass(uuid) {
		const response = await requestService.delete(`citypasses/attraction/` + uuid, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async checkAdmin() {
		const response = await requestService.get(`tools/is-admin/`, {
			headers: {
				Authorization: `Bearer ${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	}
}