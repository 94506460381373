<template>
  <v-main>
    <v-container class="pt-0 mt-0">
      <v-row align="center" class="px-6 mt-1">
        <v-divider class="mr-5"></v-divider>
        <a
          class="d-flex align-center"
          style="text-decoration: none;"
          href="https://www.hoozie.io"
          target="_blank"
          @click="toHoozie()"
          ><img src="@/assets/hoozieLogo.png" style="margin-right: 8px;r" />
          <span class="poweredByHoozieText">{{
            "city_passes_label_powered_by_Hoozie" | localize
          }}</span></a
        >
        <v-divider class="ml-5"></v-divider>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {};
</script>
<style>
.poweredByHoozieText {
  font-weight: 600;
  font-size: 14px;
  line-height: 108.4%;
  color: #6abaa4;
}
</style>