var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(!_vm.loggedUser)?_c('v-icon',{staticClass:"ml-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"#e62076"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v("mdi-account-circle-outline")]):_vm._e()],1),(_vm.loggedUser)?_c('VMenu',{staticStyle:{"z-index":"1000"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"mt-0",attrs:{"bordered":"","color":"green","dot":"","value":_vm.loggedUser ? true : false,"overlap":""}},[_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"#e62076"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-circle-outline")])],1)]}}],null,false,442620603)},[_c('VList',{staticClass:"ma-0",attrs:{"min-width":"140px"}},[_c('VListItemGroup',{attrs:{"color":"#e62076"},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [(!_vm.loggedUser)?_c('VListItem',{staticClass:"list_items",on:{"click":function($event){_vm.openModal(), toggle}}},[_c('span',{staticClass:"routeLink"},[_vm._v(_vm._s(_vm._f("localize")("header_login_signUp_label"))+" ")])]):_vm._e()]}}],null,false,2024449811)}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [(_vm.loggedUser)?_c('VListItem',{staticClass:"list_items",on:{"click":toggle}},[_c('router-link',{staticClass:"routeLink",attrs:{"to":"/profile"}},[_vm._v(_vm._s(_vm._f("localize")("header_my_account")))])],1):_vm._e()]}}],null,false,2778484125)}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [(_vm.loggedUser)?_c('VListItem',{staticClass:"list_items",attrs:{"value":"2"},on:{"click":function($event){_vm.logOut(), toggle}}},[_vm._v(_vm._s(_vm._f("localize")("profile_button_log_out")))]):_vm._e()]}}],null,false,2861275392)})],1)],1)],1):_vm._e(),(_vm.loginModal)?_c('login-modal',{on:{"close_modal":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }