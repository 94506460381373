<template>
  <VMenu offset-y v-model="menu" style="z-index: 1000">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-row no-gutters class="mt-1" align="center">
          <span
            ><img
              style="
                height: 15px;
                width: 25px;
                border-radius: 10px;
                margin-top: 2px;
              "
              :src="languages[selectedItem].src" /></span
          ><span
            class="ml-2 d-block d-lg-none"
            :style="'font-style: normal;font-weight: 500;font-size: 20px;line-height: 26px; margin-bottom: 3px;'"
            >{{ languages[selectedItem].text }}</span
          >
          <span
            class="ml-2 d-none d-lg-block"
            style="
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 2px;
            "
            >{{ languages[selectedItem].text }}</span
          >
          <v-icon class="mb-1" v-if="!menu">mdi-menu-down</v-icon>
          <v-icon class="mb-1" v-if="menu">mdi-menu-up</v-icon>
        </v-row>
      </span>
    </template>
    <VList>
      <VListItemGroup
        color="#e62076"
        v-model="selectedItem"
        @change="updateLang"
      >
        <VListItem v-for="(item, i) in languages" :key="i.value">
          <v-row no-gutters>
            <span
              ><img
                style="height: 15px; width: 25px; border-radius: 10px"
                :src="item.src" /></span
            ><span class="ml-2">{{ item.text }}</span>
          </v-row>
        </VListItem>
      </VListItemGroup>
    </VList>
  </VMenu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    languages: [
      {
        text: "EN",
        value: "en",
        src: require("@/assets/usa_flag.png"),
      },
      {
        text: "MX",
        value: "es",
        src: require("@/assets/mx_flag.png"),
      },
    ],
    selectedItem: 1,
    isEnLocale: true,
    menu: false,
  }),
  mounted() {
    if (this.loggedUser) {
      this.isEnLocale = this.loggedUser.locale === "es";
    } else {
      this.isEnLocale = this.user.locale === "es";
    }
    if (this.isEnLocale) {
      this.selectedItem = 1;
    } else {
      this.selectedItem = 0;
    }
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    ...mapActions(["updateInfo"]),
    ...mapActions(["updateInfoLogged"]),
    async updateLang() {
      if (this.loggedUser != null) {
        // this.$route.name == 'attractions' ? this.$router.go() : '';
        await this.updateInfoLogged({
          locale: this.languages[this.selectedItem].value,
        });
      } else {
        await this.updateInfo({
          locale: this.languages[this.selectedItem].value,
        });
      }
      
    },
  },
  watch: {
    loggedUser: {
      deep: true,
      handler() {
        if (this.loggedUser) {
          this.isEnLocale = this.loggedUser.locale === "es";
        } else {
          this.isEnLocale = this.user.locale === "es";
        }
        if (this.isEnLocale) {
          this.selectedItem = 1;
        } else {
          this.selectedItem = 0;
        }
      },
    },
  },
};
</script>

<style scoped>
.v-list-item {
  min-height: 35px !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
</style>