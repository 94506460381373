import { mapGetters } from "vuex";

export default {
    data: () => ({
    }),
    computed: {
        ...mapGetters(["loggedUser"]),
        ...mapGetters(["user"]),
        locale() {
            return this.loggedUser
                ? this.loggedUser.locale
                : this.user.locale;
        },
        admin() {
            return this.loggedUser && this.loggedUser.admin
        },
    }
}