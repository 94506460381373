import axios from 'axios'
import store from '@/store';

const api = process.env.VUE_APP_API_IOP
const api_campus = process.env.VUE_APP_API_CAMPUS

export default {
	async getCountry() {
		const response = await axios.get(`${api_campus}/lists/countries?lang=${store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale}`)
		return response?.data
	},
    async getNeighbourhood() {
		const response = await axios.get(`${api}/neighbourhood?lang=${store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale}`)
		return response?.data
	},
	async getState(id){
		const response = await axios.get(`${api_campus}/lists/states?id=${parseInt(id)}&lang=${store.getters.loggedUser ? store.getters.loggedUser.locale : store.getters.user.locale}`)
		return response?.data
	}
}