export default {
    data: () => ({
    }),
    computed: {
        isProd() {
            return (!window.location.href.includes('localhost') && !window.location.href.includes('dev'))
            // return (!window.location.href.includes('dev'))
        },
        additionalUuid() {
            if (this.isProd) {
                return "9561f4e4-9510-48ab-810c-d50209c3a548"
            }
            else {
                return "af2c5802-4e1e-4b1b-9f6c-be8cad3416b5"
            }
        },
    }
}