<template>
  <div id="app">
    <v-app>
      <v-idle
        @idle="onidle"
        :events="['keydown', 'mousedown', 'touchstart']"
        :duration="900"
        hidden
        :reminders="[780]"
        @remind="onRemind"
      />
      <!-- <announcement-modal
        :visible="announcementModal"
        v-if="announcementModal && !loading"
        @close="announcementModal = false"
        :autoclose="true"
        style="min-height: 100vh !important; position: fixed; top: 0px"
      /> -->

      <secure-page v-if="secure" @success="successSecure" />
      <div v-if="loading" class="d-flex align-center" style="min-height: 100vh">
        <v-progress-circular
          v-if="loading"
          class="mx-auto"
          indeterminate
          color="#E62076"
        ></v-progress-circular>
      </div>
      <Header
        ref="header"
        :key="componentKey"
        v-if="!loading && !secure"
        @display="changeMobile"
        @closeCart="showCart = false"
        :showCart="showCart"
      />
      <poweredByHoozieLabel v-if="!loading && !secure" />
      <RouterView
        :key="componentKey + 1"
        v-if="!loading && !secure"
        :style="!mobile ? 'display: none' : 'display: block'"
        @openCart="openCart"
      />
      <Footer
        aria-atomic=""
        :key="componentKey + 2"
        v-if="!loading && !secure"
      />
      <transition name="fade">
        <v-btn
          transition="scroll-y-transition"
          v-scroll="onScroll"
          v-show="top"
          small
          dark
          fixed
          class="to-top-btn"
          :style="isMobile && isMobileMenu ? 'bottom: 150px' : ''"
          color="#FFFFFF"
          @click="toTop"
        >
          <span style="color: #6abaa4">{{ "to_top_button" | localize }}</span>
          <v-icon color="#6ABAA4">mdi-arrow-up</v-icon>
        </v-btn>
      </transition>
      <!-- <SocialChat icon :attendants="attendants" v-if="!loading && !secure">
        <h2 slot="header">
          {{
            "chat_with_us" | localize
          }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </h2>
        <template v-slot:button>
          <div
            sstyle="right: -15px !important; bottom: -14px; position: absolute; "
          >
            <img
              src="./assets/chat.svg"
              style="position: relative; right: 10px; bottom: 9px"
            />
            <div
              class="text--white"
              style="
                position: absolute;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
              "
              :style="
                loggedUser
                  ? loggedUser.locale == 'en'
                    ? 'bottom: 16px'
                    : 'bottom: 8px'
                  : user.locale == 'en'
                  ? 'bottom: 16px'
                  : 'bottom: 8px'
              "
            >
              {{ "chat_with_us" | localize }}
            </div>
          </div>
        </template>
        <small slot="footer">
          <div class="footer-text">
            <b>Open from Mon to Fri from 9 AM to 5 PM</b> <br />
            Guadalajara Mexico time CST
          </div>
        </small>
      </SocialChat> -->
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "./components/Footer.vue";
import poweredByHoozieLabel from "@/components/UI/poweredByHoozieLabel.vue";
import languageService from "./requests/Language/languageService";
import { mapGetters, mapActions } from "vuex";
import SecurePage from "@/views/SecurePage.vue";
import cityMixin from "@/mixins/cityMixin";
import mobile from "@/mixins/mobileMixin";
// import announcementModal from "@/components/UI/announcementModal.vue";
import serverMixin from "@/mixins/serverMixin";
// import IPService from '@/requests/IPService/IPService.js'

export default {
  components: {
    Header,
    Footer,
    poweredByHoozieLabel,
    // announcementModal,
    SecurePage,
  },
  name: "App",
  mixins: [cityMixin, mobile, serverMixin],
  data() {
    return {
      attendants: [
        // {
        //   app: "whatsapp",
        //   label: "",
        //   name: "Guadalajara Pass",
        //   number: "393314393373",
        //   avatar: {
        //     src: require("./assets/technical-support.png"),
        //     alt: "avatar",
        //   },
        // },
      ],
      secure: false,
      secureCheck: false,
      announcementCheck: false,
      authorized: true,
      componentKey: 0,
      loading: true,
      mobile: true,
      showCart: false,
      showLogInModal: false,
      // announcementModal: false,
      top: false,
      userLocale: "",
      systemLocale: "",
    };
  },

  watch: {
    "$route.path": {
      handler: function (/*path*/) {
        this.header = true;
        if (this.$route.path == "/signin" || this.$route.path == "/signup") {
          this.authorized = false;
        }
      },
    },
    "$route.name": {
      handler() {
        if (this.isProd && this.mazatlan) {
          if (
            !this.isMobile &&
            this.$route.name != "app-view" &&
            this.$route.name != "payment_status" &&
            this.secureCheck
          ) {
            this.secure = true;
            this.secureCheck = false;
          } else {
            this.secureCheck = false;
          }
        }
      },
    },
    loggedUser: {
      deep: true,
      handler() {
        this.changeLabelInChat();
      },
    },
    user: {
      deep: true,
      handler() {
        this.changeLabelInChat();
      },
    },
  },
  beforeMount() {
    if (this.isProd && this.mazatlan) {
      if (!this.$route.name) {
        this.secureCheck = true;
      } else if (
        !this.isMobile &&
        this.$route.name != "app-view" &&
        this.$route.name != "payment_status" &&
        !this.secureCheck
      ) {
        this.secure = true;
      }
    }

    // const res = IPService.getIPData()
    // console.log(res)

    if (!this.user.locale) {
      const systemLocale =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language;

      this.systemLocale = systemLocale;
      this.testingLanguageSnackbar = true;
      let locale = "en";
      if (systemLocale.includes("es")) {
        locale = "es";
      }
      this.updateInfo({
        locale: locale,
      });
    }

    this.getLanguage();

    if (this.mazatlan) {
      document.title = "Mazatlan Pass";
    } else document.title = "Guadalajara Pass";
  },
  mounted() {
    this.changeLabelInChat();
    if (this.$route.path == "/signin" || this.$route.path == "/signup") {
      this.authorized = false;
    }
  },
  created() {
    window.addEventListener("beforeunload", function () {
      localStorage.time = new Date();
    });
    if (localStorage.time) {
      let date = new Date();
      let diff = Math.abs(date - new Date(localStorage.time));
      if (diff * 0.001 > 900) {
        this.$store.commit("clearUserLogged");
      }
      localStorage.removeItem("time");
    }
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["updateInfo"]),
    async onidle() {
      if (this.loggedUser) {
        this.$store.commit("clearUserLogged");
      }
    },
    onRemind(/*time*/) {
      if (
        localStorage.count &&
        localStorage.count != "0" &&
        this.$route.name != "mobile_checkout" &&
        this.$route.name != "Checkout"
      ) {
        this.$refs.header.openCart();
      }
    },
    successSecure() {
      this.$router.push(this.$route.path);
      this.secure = false;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.top = top > 30;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    changeLabelInChat() {
      this.userLocale = this.loggedUser
        ? this.loggedUser.locale
        : this.user.locale;
      if (this.attendants.length > 0) {
        this.attendants[0].label =
          this.userLocale == "en" ? "Technical support" : "Apoyo técnico";
      }
    },
    changeMobile(value) {
      this.mobile = !value;
    },
    getLanguage() {
      languageService
        .getLanguage()
        .then(() => {
          this.componentKey++;
          this.loading = false;
          // if (this.$route.path == "/" && !this.mazatlan && this.isProd) {
          //   setTimeout(() => {
          //     this.announcementModal = true;
          //   }, 1500);
          // this.announcementModal = true;
          //}
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openCart() {
      this.showCart = false;
      setTimeout(() => {
        this.showCart = true;
      }, 10);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
body {
  overflow-y: hidden;
  overflow-x: hidden;
}
* {
  --vsc-bg-header: #6abaa4;
  --vsc-bg-footer: #e6e6e6;
  --vsc-text-color-header: white;
  --vsc-text-color-footer: rgb(67, 67, 67);
  --vsc-bg-button: #6abaa4;
  --vsc-text-color-button: white;
  --vsc-outline-color: transparent;
  --vsc-border-color-bottom-header: #128c7e;
  --vsc-border-color-top-footer: #c0c0c0;
  font-family: "Montserrat", sans-serif;
}
.footer-text {
  line-height: 1.3;
}

.to-top-btn {
  bottom: 70px;
  right: 28px;
  opacity: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  z-index: 110;
}
@media (max-width: 600px) {
  .vue-image-crop-upload .vicp-wrap {
    width: 85% !important;
    height: 480px !important;
  }
  .vue-image-crop-upload .vicp-drop-area {
    height: 300px !important;
  }
  .vue-image-crop-upload .vicp-crop-right {
    margin-left: 70px !important;
    float: left !important;
  }
  .vicp-range input[type="range"] {
    display: none !important;
  }
  .vicp-range {
    margin-left: 100px !important;
  }
  .vicp-range .vicp-icon6 {
    right: unset !important;
    margin-left: 25px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: scale 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  scale: 0;
}
.swiper-wrapper {
  align-items: center !important; /* add this will all relevant prefixes */
}
.pass-logo {
  width: 145px;
  &-mzt {
    width: 60px;
  }
}
</style>
