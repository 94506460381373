<template>
  <div>
    <div>
      <v-icon
        style="cursor: pointer"
        @click="openModal()"
        class="ml-3"
        v-if="!loggedUser"
        color="#e62076"
        >mdi-account-circle-outline</v-icon
      >
    </div>
    <VMenu offset-y style="z-index: 1000" v-if="loggedUser">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bordered
          color="green"
          dot
          :value="loggedUser ? true : false"
          overlap
          class="mt-0"
          ><v-icon v-bind="attrs" v-on="on" class="ml-3" color="#e62076"
            >mdi-account-circle-outline</v-icon
          ></v-badge
        >
      </template>
      <VList class="ma-0" min-width="140px">
        <VListItemGroup v-model="activeItem" color="#e62076">
          <v-item v-slot="{ toggle }">
            <VListItem
              class="list_items"
              v-if="!loggedUser"
              @click="openModal(), toggle"
              ><span class="routeLink"
                >{{ "header_login_signUp_label" | localize }}
              </span></VListItem
            >
          </v-item>
          <v-item v-slot="{ toggle }">
            <VListItem class="list_items" v-if="loggedUser" @click="toggle"
              ><router-link to="/profile" class="routeLink">{{
                "header_my_account" | localize
              }}</router-link></VListItem
            >
          </v-item>
          <v-item v-slot="{ toggle }">
            <VListItem
              @click="logOut(), toggle"
              class="list_items"
              v-if="loggedUser"
              value="2"
              >{{ "profile_button_log_out" | localize }}</VListItem
            >
          </v-item>
        </VListItemGroup>
      </VList>
    </VMenu>
    <login-modal v-if="loginModal" @close_modal="closeModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loginModal from "./LoginModal.vue";
import CartService from "../../requests/Cart/CartService";
export default {
  components: {
    loginModal,
  },
  data: () => ({
    activeItem: -1,
    loginModal: false,
  }),
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    ...mapActions(["updateInfo"]),
    closeModal() {
      this.loginModal = false;
    },
    openModal() {
      this.loginModal = true;
      this.activeItem = -1;
    },
    async logOut() {
      await this.updateInfo({
        locale: this.loggedUser.locale,
      });
      this.$store.commit("clearUserLogged");
      await CartService.clearCart();
      this.$router.push("/");
      this.activeItem = -1;
    },
  },
  watch: {
    activeItem: {
      deep: true,
      handler() {
        console.log("work", this.activeItem);
      },
    },
  },
};
</script>

<style scoped>
.list_items {
  font-weight: 600;
  font-size: 13.4829px;
  line-height: 16px;
}
.v-list-item {
  min-height: 30px !important;
}
.routeLink {
  text-decoration: none;
  color: black;
}
</style>