import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store'
import * as actionTypes from "@/store/modules/mztAttractions/types/actions";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomePage.vue'),
    },
    {
        path: '/attractions',
        name: 'attractions',
        component: () => import('@/views/OurAttractions.vue'),
    },
    {
        path: '/attractions/:id',
        name: 'attractionsDetailPage',
        component: () => import('@/views/AttractionDetailPage.vue'),
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        component: () => import('@/views/FAQ.vue'),
    },
    {
        path: '/where_to_stay/:selected',
        name: 'WhereToStay',
        component: () => import('@/views/WhereToStay.vue'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/userProfile.vue'),
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('@/views/Checkout.vue'),
    },
    {
        path: '/city_passes',
        name: 'CityPasses',
        component: () => import('@/views/CityPasses.vue'),
    },
    {
        path: '/signin',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/signup',
        name: 'Registration',
        component: () => import('@/views/Registration.vue'),
    },
    {
        path: '/email_check',
        name: 'EmailCheck',
        component: () => import('@/views/EmailCheck.vue'),
    },
    {
        path: '/sign_up_success',
        name: 'Registration success',
        component: () => import('@/views/RegistrationSuccess.vue'),
    },
    {
        path: '/reset_password',
        name: 'resetPassword',
        component: () => import('@/views/ResetPassword.vue'),
    },
    {
        path: '/hoozie_rewards',
        name: 'hoozie_rewards',
        component: () => import('@/views/HoozieRewards.vue'),
    },
    {
        path: '/profile/detail/info/my_citypass/:citypass_uid/:order_uid/:parent_uuid',
        name: 'detail_info',
        component: () => import('@/views/ProfileCitypassDetailPage.vue'),
    },
    {
        path: '/success',
        name: 'payment_status',
        component: () => import('@/components/Checkout/checkoutSuccess.vue'),
    },
    {
        path: '/secure',
        name: 'secure',
        component: () => import('@/views/SecurePage.vue'),
    },
    {
        path: '/mobile/city_passes',
        name: 'mobile_citypass',
        component: () => import('@/views/MobileVersion.vue'),
    },
    {
        path: '/mobile/attractions',
        name: 'mobile_attraction',
        component: () => import('@/views/MobileVersion.vue'),
    },
    {
        path: '/mobile/checkout',
        name: 'mobile_checkout',
        component: () => import('@/views/MobileVersion.vue'),
    },
    {
        path: '/mobile/attractions/:id',
        name: 'mobile-attractions',
        component: () => import('@/views/MobileAttractionDetails.vue'),
    },
    {
        path: '/app_view/login/:token/:menu/:locale',
        name: 'app-view',
        component: () => import('@/views/LoginApp/LoginApp.vue')
    },
    {
        path: '/app_view/login/:menu/:locale',
        name: 'app-view',
        component: () => import('@/views/LoginApp/LoginApp.vue')
    },
    {
        path: '/orders/support',
        name: 'support',
        component: () => import('@/views/SupportPanel/SupportPanel.vue'),
    },
    {
        path: '/attraction/prebooking',
        name: 'prebooking',
        component: () => import('@/views/PrebookingPanel/PrebookingPanel.vue'),
    },

];
const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name != 'attractionsDetailPage' && to.name != 'attractions' && to.name != 'mobile_attraction' && to.name != 'mobile-attractions' && to.name != 'mobile_checkout') {
        if (localStorage.selectedCitypassId) {
            localStorage.removeItem('selectedCitypassId')
        }
        if (localStorage.date) {
            localStorage.removeItem('date')
        }
        if (localStorage.adultNumber) {
            localStorage.removeItem('adultNumber')
        }
        if (localStorage.childNumber) {
            localStorage.removeItem('childNumber')
        }
        if (localStorage.isIncludeGDLPublicTransport) {
            localStorage.removeItem('isIncludeGDLPublicTransport')
        }
        if (localStorage.attractions) {
            localStorage.removeItem("attractions");
        }
        if (localStorage.checkedNum) {
            localStorage.removeItem("checkedNum");
        }
        if (localStorage.selectedCitypass) {
            localStorage.removeItem("selectedCitypass");
        }

    }
    next()
});

router.afterEach((to) => {
    if (to.name != 'attractionsDetailPage' && to.name != 'attractions' && to.name != 'mobile_attraction' && to.name != 'mobile-attractions' && to.name != 'mobile_checkout') {
        if (process.env.VUE_APP_TYPE == 'Mazatlan') {
            Vue.nextTick(() => {
                store.dispatch(`mztAttractions/${actionTypes.FETCH_DATA}`, {})
            })
        }
    }
});

export default router;