import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
  [actions.FETCH_MOBILE]: async ({ commit }, state = false) => {
      commit(mutations.SET_MOBILE, state);
  },
  [actions.FETCH_MENU]: async ({ commit }, state = false) => {
      commit(mutations.SET_MENU, state);
  },
};