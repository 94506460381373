<template>
  <div>
    <v-container style="min-height: 100vh">
      <v-row justify="center" align="center" style="min-height: 80vh">
        <v-col cols="12" lg="5" md="5" xl="5" sm="5">
          <v-form @submit.prevent="checkPass()">
            <v-card class="pa-5">
              <v-card-text>
                <v-row no-gutters>
                  <v-col class="text-center">
                    <img :src="require('@/assets/' + $options.filters.city('main_logo'))" width="300px" />
                  </v-col>
                </v-row>
                <v-text-field
                  class="mt-5 mb-1"
                  v-model="password"
                  label="Password"
                  dense
                  type="password"
                  hide-details
                  outlined
                >
                </v-text-field>
                <span style="color: red" v-if="messages">{{ messages }}</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn dark color="#e62076" @click="checkPass()">Submit</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [cityMixin],
  data: () => ({
    password: null,
    messages: null,
  }),
  methods: {
    checkPass() {
      this.messages = null;
      if (!this.mazatlan && this.password == "CityPassSecure00") {
        this.$emit("success");
      } else if (this.mazatlan && this.password == "CityPassSecure11") {
        this.$emit("success");
      } else {
        this.messages = "Wrong password";
      }
    },
  },
};
</script>