<template>
  <v-dialog v-model="termsModal" width="600px">
    <v-card color="white" class="black--text" v-if="terms">
      <v-card-title>{{
        "footer_terms_and_conditions" | localize
      }}</v-card-title>
      <v-card-text v-html="$options.filters.localize('privacy_policy')"> </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <!-- REFUND POLICY -->
    <v-card color="white" class="black--text" v-if="refundedPolicy">
      <v-card-title>{{ "refund_policy_label" | localize }}</v-card-title>
      <v-card-text v-html="text"> </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <!-- BOOKING -->
    <v-card color="white" class="black--text" v-if="booking">
      <v-card-title>{{
        "attractions_prebooking_window_title" | localize
      }}</v-card-title>
      <v-card-text v-html="text"> </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <!-- COOKIES -->
    <v-card color="white" class="black--text" v-if="cookies">
      <v-card-title>Cookies</v-card-title>
      <v-card-text v-html="$options.filters.localize('Cookies')"></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- License -->
    <v-card color="white" class="black--text" v-if="license">
      <v-card-title>{{ "checkout_license_checkbox" | localize }}</v-card-title>
      <v-card-text><div v-html="licenseText"></div></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- Public Transport -->
    <v-card color="white" class="black--text" v-if="publicTransport">
      <v-card-title>{{
        "home_public_transport_label" | localize
      }}</v-card-title>
      <v-card-text v-html="$options.filters.localize('Public_Transport')">
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- PRIVACY POLICY -->
    <v-card
      color="white"
      class="black--text"
      v-if="
        !terms &&
        !refundedPolicy &&
        !booking &&
        !cookies &&
        !license &&
        !publicTransport
      "
    >
      <v-card-title>{{ "footer_privacy_policy" | localize }}</v-card-title>
      <v-card-text v-html="$options.filters.localize('Policy')">
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="termsModal = false" color="#e62076">{{
          "button_close" | localize
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import en from "@/locales/en.json";
import es from "@/locales/es.json";

const locales = {
  en: en,
  es: es,
};
export default {
  data: () => ({
    termsModal: true,
    terms: false,
    refundedPolicy: false,
    booking: false,
    cookies: false,
    license: false,
    publicTransport: false,
    textExample:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra" +
      "iaculis ex, non volutpat augue fringilla semper. Praesent sed laoreet" +
      "nisl. Donec vehicula vitae magna eu dictum. Phasellus vestibulum enim" +
      "quis magna commodo euismod. Vivamus ex dolor, laoreet vitae semper nec," +
      "ultdicies sed orci. Integer tdistique justo eu massa sagittis lobortis." +
      "Nunc nec turpis id felis dictum sodales. Nulla eu pretium magna. Morbi" +
      "ut viverra lectus. Nunc sed dolor vel risus feugiat volutpat. Etiam" +
      "lobortis tellus ut vulputate fringilla. Aliquam ut ligula purus. Fusce" +
      "id turpis convallis, varius libero et, sagittis justo. Suspendisse nec" +
      "tincidunt nisl, vel egestas ligula. Proin ipsum orci, elementum non arcu" +
      "et, dapibus interdum nisi. Fusce pharetda lectus sem, et rutdum ante" +
      "porttitor eget. Integer maximus placerat sagittis. Nulla efficitur" +
      " interdum finibus. Class aptent taciti sociosqu ad litora torquent per" +
      "conubia nostda, per inceptos himenaeos. Vestibulum viverra, sem non" +
      "semper elementum, augue turpis pharetda elit, non sagittis quam arcu ut" +
      "odio. Mauris vitae mi id nisl mollis aliquet vulputate eu magna. Aenean" +
      "nec convallis quam. Vivamus eu bibendum tellus. Phasellus hendrerit" +
      " auctor ligula. Nullam aliquam tdistique orci at pulvinar. Sed pulvinar" +
      " eros eget mauris auctor tincidunt. Cras eget imperdiet purus. Vestibulum" +
      "ante ipsum primis in faucibus orci luctus et ultdices posuere cubilia" +
      "curae; In sodales risus dui, ac tempus felis aliquet et. Curabitur" +
      "cursus lectus sed dui dapibus suscipit. Pellentesque habitant morbi" +
      " tdistique senectus et netus et malesuada fames ac turpis egestas. Donec" +
      "ultdices eget tortor quis aliquet. Nullam quis auctor neque, eget" +
      "ullamcorper nisl. Suspendisse faucibus sem ut arcu venenatis interdum." +
      "Ut at tempus purus. Donec sit amet pellentesque augue, a ultdices nisi." +
      "Orci varius natoque penatibus et magnis dis parturient montes, nascetur" +
      "ridiculus mus. Maecenas sit amet interdum erat, a mattis justo. Maecenas" +
      "nec pulvinar nunc. Praesent aliquam arcu finibus posuere consectetur." +
      " Donec posuere, odio at efficitur lobortis, libero tortor condimentum mi," +
      "eu hendrerit magna risus commodo diam. Cras posuere, eros vel aliquam" +
      "auctor, mi orci gravida turpis, ut sagittis erat est et justo. Lorem" +
      "ipsum dolor sit amet, consectetur adipiscing elit. Cras pretium nunc sit" +
      "amet laoreet mattis. Quisque feugiat magna in lobortis molestie. Aenean" +
      "iaculis est sem, id lobortis erat rutdum ac. Fusce bibendum nisl justo," +
      "et ornare lacus faucibus nec. Donec malesuada mauris in dolor aliquam," +
      "vitae placerat est posuere. Mauris cursus sodales libero vitae" +
      "porttitor. Sed in purus dictum, fermentum odio eu, sagittis metus.",
    licenseText: "",
  }),
  props: {
    text: {
      require: false,
    },
    rules: {
      require: false,
    },
  },
  mounted() {
    this.checkRules();
    this.localizeFilter("checkout_license_text");
  },
  methods: {
    localizeFilter(key) {
      let locale = "es";
      if (store.getters.loggedUser) {
        locale = store.getters.loggedUser.locale || "es";
      } else {
        locale = store.getters.user.locale || "es";
      }
      this.licenseText = locales[locale][key];
    },
    checkRules() {
      console.log("rules", this.rules);
      if (this.rules == "terms") {
        this.terms = true;
      } else if (this.rules == "refundPolicy") {
        this.refundedPolicy = true;
      } else if (this.rules == "booking") {
        this.booking = true;
      } else if (this.rules == "cookies") {
        this.cookies = true;
      } else if (this.rules == "license") {
        this.license = true;
      } else if (this.rules == "publicTransport") {
        this.publicTransport = true;
      }
    },
  },
  watch: {
    termsModal: {
      handler() {
        this.termsModal = false;
        this.$emit("close_modal");
      },
    },
    text: {
      handler() {
        this.checkRules();
      },
    },
  },
};
</script>

<style>
.table-row td {
  border: 1px rgb(67, 67, 67) solid !important;
  padding: 4px;
}
</style>
