<template>
  <div>
    <v-col>
      <div class="cart mb-5" v-for="order in cart" :key="order.id">
        <!-- Order card header -->
        <div class="header px-0" :style="'background-color:' + order.color">
          <v-row no-gutters align="center" class="pt-1">
            <v-col class="pa-0 pl-5 pt-1" cols="7">
              <span
                style="font-weight: 700; font-size: 20px"
                class="white--text"
              >
                {{
                  locale == "en" ? order.cityPassTitleEn : order.cityPassTitleEs
                }}
              </span>
            </v-col>
            <v-col class="pa-0 col-5">
              <v-row justify="end" align="center" class="mr-2">
                <div class="mt-1 mr-5">
                  <span class="white--text">
                    {{
                      new Date(order.started_at).toLocaleDateString("en-gb", {})
                    }}
                    -
                    {{ order.end_date.toLocaleDateString("en-gb", {}) }}
                  </span>
                </div>

                <img
                  style="cursor: pointer"
                  class="mt-1"
                  src="@/assets/delete-icon-cart.svg"
                  alt="delete"
                  @click="$emit('deleteCitypassFromCart', order.uuid)"
                />
              </v-row>
            </v-col>
          </v-row>
        </div>
        <!-- /Order card header -->
        <!-- Order card main -->
        <v-row no-gutters align="start" style="margin-top: 10px">
          <v-col style="margin-left: 20px">
            <v-row align="start">
              <v-col>
                <v-row
                  align="center"
                  v-for="number in order.number"
                  :key="number.id"
                  class="mt-1 mb-auto"
                  :class="{ 'mb-2': !mazatlan }"
                >
                  <v-col
                    cols="6"
                    class="py-0 mb-2"
                    :style="
                      number.type == 'Kids' && mazatlan
                        ? 'margin-top: 10px;'
                        : ''
                    "
                  >
                    <span class="textStyle">{{
                      number.type == "Adult"
                        ? "attractions_adult"
                        : "cart_label_child" | localize
                    }}</span
                    ><br />
                    <span
                      class="textStyle"
                      v-if="mazatlan"
                      style="font-weight: 400 !important"
                    >
                      MX$
                      {{
                        new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                        }).format(number.currentPrice)
                      }}</span
                    >
                  </v-col>
                  <!-- Adult quantity -->
                  <v-row no-gutters align="center" justify="end">
                    <img
                      src="@/assets/minus.svg"
                      alt="minus"
                      style="cursor: pointer"
                      @click="
                        number.number > 0
                          ? number.number--
                          : (number.number = 0),
                          changeSubtotal()
                      "
                      v-if="number.number > 0"
                    />
                    <!-- /Adult quantity -->
                    <!-- Kids qunatity -->
                    <img
                      v-else
                      src="@/assets/minus-disabled.svg"
                      alt="minus-disabled"
                      style="cursor: pointer"
                      @click="
                        number.number > 1
                          ? number.number--
                          : number.type == 'Adult'
                          ? (number.number = 1)
                          : (number.number = 0),
                          changeSubtotal()
                      "
                    />
                    <div
                      style="
                        width: 40px;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span class="textStyle">{{ number.number }}</span>
                    </div>
                    <img
                      src="@/assets/plus.svg"
                      alt="plus"
                      style="cursor: pointer"
                      @click="number.number++, changeSubtotal()"
                      v-if="
                        (number.type == 'Adult' &&
                          number.number < number.maxAdultAvailable) ||
                        (number.type == 'Kids' &&
                          number.number < number.maxChildAvailable)
                      "
                    />
                    <img
                      v-else
                      src="@/assets/plus-disabled.svg"
                      alt="plus"
                      style="cursor: pointer"
                    />
                  </v-row>
                  <!-- @click="
                        number.type == 'Adult'
                          ? ((number.number = number.maxAdultAvailable), changeSubtotal())
                          : ((number.number = number.maxChildAvailable), changeSubtotal()) -->
                  <!-- /Kids qunatity -->
                </v-row>
                <div class="mt-1 mb-3">
                  <span class="textStyle">{{
                    "infants_label" | localize
                  }}</span>
                </div>

                <!-- Banking charges -->
                <v-row v-if="mazatlan" no-gutters class="py-0">
                  <v-col cols="6" class="px-0 py-0">
                    <label
                      style="font-size: 16px; color: #212121"
                      :style="
                        order.subtotal.toString().length > 6
                          ? 'font-size: 14px'
                          : 'font-size:15px'
                      "
                    >
                      {{ "banking_charges_label" | localize }}</label
                    >
                  </v-col>
                  <v-col
                    cols="6"
                    class="px-0 py-0"
                    style="text-align: end"
                    :style="
                      order.subtotal.toString().length > 6
                        ? 'font-size: 14px'
                        : 'font-size:15px'
                    "
                  >
                    <label
                      >MX$
                      {{
                        new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                        }).format(order.fee)
                      }}</label
                    >
                  </v-col>
                </v-row>
                <!-- /Banking charges -->
                <!-- Additional OR PUBLIC TRANSPORT -->
                <v-row
                  no-gutters
                  class="py-0"
                  align="center"
                  v-if="order.additional && order.additional !== 0"
                >
                  <v-col
                    :class="mazatlan ? 'col-6 px-0 py-0' : 'col-12 px-0 py-0'"
                  >
                    <label
                      style="font-size: 16px; color: #212121"
                      :style="
                        order.subtotal.toString().length > 6
                          ? 'font-size: 14px'
                          : 'font-size:15px'
                      "
                    >
                      {{ `${mazatlan ? "mzt_" : ""}bus_metro_label` | localize
                      }}{{ "public_transport_label2" | localize }}</label
                    >
                  </v-col>
                  <v-col
                    cols="6"
                    class="px-0 py-0"
                    style="text-align: end"
                    v-if="mazatlan"
                    :style="
                      order.subtotal.toString().length > 6
                        ? 'font-size: 14px'
                        : 'font-size: 15px'
                    "
                  >
                    <label
                      >MX$
                      {{
                        new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                        }).format(order.additional)
                      }}</label
                    >
                  </v-col>
                </v-row>
                <!-- /Additional -->
                <!-- Total cost -->
                <v-row no-gutters class="py-0" :class="{ 'pt-4': !mazatlan }">
                  <v-col
                    cols="6"
                    class="py-0 px-0"
                    style="font-weight: 500; font-size: 16px; color: #212121"
                  >
                    <!-- <span>{{ "attractions_starting_date" | localize }}</span
                    ><br /> -->
                    <!-- <span>Booking Fee</span><br /> -->
                    <span class="font-weight-bold">{{
                      "cart_label_total_cost" | localize
                    }}</span>
                  </v-col>
                  <v-col
                    class="py-0 px-0"
                    cols="6"
                    style="font-weight: 500; color: #212121; text-align: end"
                    :style="
                      order.subtotal.toString().length > 6
                        ? 'font-size: 14px'
                        : 'font-size:15px'
                    "
                  >
                    <!-- <span>{{
                      new Date(order.started_at).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}</span
                    ><br /> -->
                    <!-- <span>MX${{ new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                        }).format(bookingFee)}}</span
                    ><br /> -->
                    <span class="font-weight-bold">
                      MX$
                      {{
                        new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                        }).format(order.subtotal)
                      }}
                    </span>
                  </v-col>
                </v-row>
                <!-- /Total cost -->
              </v-col>
              <v-divider
                vertical
                style="
                  opacity: 0.25;
                  border: 1px solid #212121;
                  margin-top: 10px;
                "
                heigth="105px"
                class="px-0 ml-10 mt-5 mb-5"
              />
              <!-- Free attraction list -->
              <v-col style="text-align: left; margin-top: 20px">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                  class="mb-2 pl-5"
                >
                  <img src="@/assets/ticket_icon.svg" alt="ticket" />
                  <v-col cols="6" class="py-0 px-0 ml-1">
                    <h3
                      style="
                        color: #e62076;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 20px;
                      "
                    >
                      {{ "cart_label_free_attractions" | localize }}
                    </h3>
                  </v-col>
                </v-row>
                <div style="text-align: center" class="pl-5">
                  <div
                    v-for="(attract, i) in order.attractions"
                    :key="i"
                    style="text-align: left"
                  >
                    <v-row no-gutters>
                      <v-col cols="2" style="text-align: center">
                        <span
                          style="
                            font-weight: 500;
                            font-size: 16px;
                            color: black;
                            text-align: left;
                          "
                          class="ml-1"
                          >{{ i + 1 }}.</span
                        >
                      </v-col>
                      <v-col cols="9" class="px-0">
                        <span
                          style="
                            font-weight: 500;
                            font-size: 16px;
                            color: black;
                            text-align: left;
                          "
                          >{{ attract.title }}</span
                        >
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- /Free attraction list-->
        </v-row>
        <!-- Order card main -->
      </div>
    </v-col>
  </div>
</template>

<script>
import AttractionsService from "../../requests/Attractions/AttractionsService";
import cityMixin from "@/mixins/cityMixin";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";

export default {
  data: () => ({
    cart: [],
    attraction: [],
    attractionUn: [],
  }),
  props: {
    cartOrder: {
      require: true,
    },
  },
  mixins: [cityMixin, userSettingsMixin],
  mounted() {
    this.cart = this.cartOrder;
    this.filter();
  },
  methods: {
    changeSubtotal() {
      this.$emit("changeSubtotal", this.cart);
    },
    async getAttraction(uid) {
      let response = await AttractionsService.getAttraction(uid);
      return response.result;
    },
    async filter() {
      for (let index = 0; index < this.cart.length; index++) {
        let attraction = [];
        let uids = [];
        for (let i = 0; i < this.cart[index].attractions.length; i++) {
          if (this.cart[index].attractions[i].is_additional == "0") {
            uids.push(this.cart[index].attractions[i].uuid);
          }
        }
        let uidsStr = String(uids);
        if (uids.length > 0) {
          await AttractionsService.getAttractionsByUids(uidsStr).then((res) => {
            if (res.result !== null) {
              attraction = res.result;
              this.cart[index].attractions = attraction.sort(function (a, b) {
                var attractionTitleA = a.title.toUpperCase();
                var attractionTitleB = b.title.toUpperCase();
                return attractionTitleA < attractionTitleB
                  ? -1
                  : attractionTitleA > attractionTitleB
                  ? 1
                  : 0;
              });
            }
          });
        }
      }
    },
  },
  watch: {
    cartOrder: {
      deep: true,
      handler() {
        this.cart = this.cartOrder;
      },
    },
  },
};
</script>

<style scoped>
.textStyle {
  font-weight: 700;
  font-size: 14px;
}
.cart {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 602px;
  padding-bottom: 30px;
}
.header {
  min-height: 44px;
  max-height: 70px;
  width: 602px;
  align-content: center;
  border-radius: 10px 10px 0px 0px;
}
</style>